import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
function Services2() {
  const [isMenuToggled, setIsMenuToggled] = useState(false);

  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuToggled(!isMenuToggled);
  };
  const [profileData, setProfileData] = useState(null);
  const [ProfileDataToEdit, setProfileDataToEdit] = useState(null);
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    // Fonction pour récupérer les données du profil par ID
    const fetchProfileData = async () => {
      try {
        // Remplacez l'URL par l'URL de votre API GET par ID
        const response = await fetch(
          `https://bridgetojustice.ai:8443/profile/${user_id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch profile data");
        }
        const data = await response.json();
        setProfileData(data.user);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    // Appelez la fonction fetchProfileData une fois lorsque le composant est monté
    fetchProfileData();
  }, []);
  // Utilisez useEffect pour attacher l'événement de basculement lors du chargement du composant
  useEffect(() => {
    const toggleButton = document.getElementById("menu-toggle");
    toggleButton.addEventListener("click", toggleMenu);

    // Nettoyez l'écouteur d'événement lors du démontage du composant
    return () => {
      toggleButton.removeEventListener("click", toggleMenu);
    };
  }, []); // Le tableau vide signifie que cela ne doit s'exécuter qu'une fois après le montage

  // Ajoutez votre logique pour basculer la classe "toggled" ici
  useEffect(() => {
    const el = document.getElementById("wrapper");
    const toggleButton = document.getElementById("menu-toggle");

    const toggleWrapperClass = () => {
      el.classList.toggle("toggled");
    };

    toggleButton.addEventListener("click", toggleWrapperClass);

    return () => {
      toggleButton.removeEventListener("click", toggleWrapperClass);
    };
  }, []);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    window.location.href = "/login";
  };
  return (
    <div className="d-flex" id="wrapper">
      <div className="bg-white" id="sidebar-wrapper">
        <div className="sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase border-bottom">
          <Link
            to="/"
            className="navbar-logo1"
            style={{
              color: "black",
              fontSize: "1.2rem",
              justifyContent: "center",
            }}
          >
            Legal Document
          </Link>
        </div>
        <div className="list-group list-group-flush my-3">
          <a
            href="/profile"
            className="list-group-item list-group-item-action bg-transparent second-text active"
            style={{ color: "black" }}
          >
            <i className="fas fa-user me-2"></i>Profil
          </a>

          <a
            href="/services2"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-tools me-2"></i>Services
          </a>

          <a
            href="/forum"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Forum
          </a>
          <a
            href="/historique"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Historique
          </a>
        </div>
        <div className="list-group mt-5">
          <a
            onClick={logout}
            className="list-group-item list-group-item-action bg-transparent text-danger fw-bold"
          >
            <i className="fas fa-power-off me-2"></i>Logout
          </a>
        </div>
      </div>

      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4">
          <div className="d-flex align-items-center">
            <i
              className="fas fa-align-left primary-text fs-4 me-3"
              id="menu-toggle"
              style={{ color: "black" }}
            ></i>
            <h2 className="fs-2 m-0">Services</h2>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            color="black"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "black" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                {/* Utilisez la classe "d-flex" pour aligner le texte et l'avatar horizontalement */}
                <a
                  className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Remplacez "chemin_vers_votre_avatar" par le chemin de votre image d'avatar */}
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnfAxGV-fZxGL9elM_hQ2tp7skLeSwMyUiwo4lMm1zyA&s"
                    alt="Avatar"
                    className="me-2 rounded-circle"
                    style={{ width: "40px", height: "35px" }}
                  />
                  {profileData ? (
                    <p style={{ color: "black" }}> {profileData.username}</p>
                  ) : (
                    <p style={{ color: "black" }}>Loading...</p>
                  )}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/profile">
                      Profile
                    </a>
                  </li>

                  <li>
                    <button className="dropdown-item" onClick={logout}>
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>

        <div className="container-fluid px-4">
          <div className="row g-3 my-2">
            <div className="col-12">
              {" "}
              {/* Utilisez la classe col-12 pour occuper toute la largeur */}
              <div
                className="p-3 bg-custom shadow-sm d-flex flex-column align-items-start rounded-4"
                style={{ backgroundColor: "black" }}
              >
                <p className="fs-6 mb-2 text-white ">Legal Document</p>
                <h3 className="fs-4 mb-5 text-white">
                  Legal Agreement: Use of AI in Document Drafting
                </h3>
                <div style={{ display: "flex" }}>
                  <button
                    className="btn btn-dark btn-sm rounded-5"
                    disabled
                    //onClick={() => navigate("/new-project")}
                  >
                    New Service
                    <i className="fas fa-plus ms-2"></i>
                  </button>
                  <button
                    className="btn btn-dark btn-sm rounded-5"
                    disabled
                    //onClick={() => navigate("/payment2")}
                    style={{ marginLeft: "10px" }}
                  >
                    Upgrade Premuim
                    <FontAwesomeIcon icon={faCrown} />
                  </button>
                </div>
              </div>
              <h2 className="fs-2 mt-5 mb-3 ">Services</h2>
              <div className="row g-3">
                {/* Carte 1 */}
                <Link to={"/settingsN"}>
                  <div className="col-md-3">
                    <div className="p-3 bg-white shadow-sm d-flex flex-column align-items-start rounded">
                      {/* Photo qui prend la largeur de la carte et une hauteur fixe */}
                      <img
                        src="https://researchmethod.net/wp-content/uploads/2023/05/Documentary_Analysis-1024x576.jpg"
                        alt="Votre Photo"
                        className="img-fluid rounded"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "150px",
                        }}
                      />

                      {/* Bouton sous la photo */}

                      {/* Description sous le bouton */}
                      <p className="fs-6 mt-2">Votre description ici...</p>
                    </div>
                  </div>
                </Link>

                {/* Carte 2 */}
                {/* <div className="col-md-3">
                  <div className="p-3 bg-white shadow-sm d-flex flex-column align-items-start rounded">
                 
                    <img
                      //src={image2}
                      alt="Votre Photo"
                      className="img-fluid rounded"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "150px",
                      }}
                    />

                  

                
                    <p className="fs-6 mt-2">Votre description ici...</p>
                  </div>
                </div>
            
                <div className="col-md-3">
                  <div className="p-3 bg-white shadow-sm d-flex flex-column align-items-start rounded">
                   
                    <img
                      //src={image3}
                      alt="Votre Photo"
                      className="img-fluid rounded"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "150px",
                      }}
                    />

                 
                    <p className="fs-6 mt-2">Votre description ici...</p>
                  </div>
                </div>
             
                <div className="col-md-3">
                  <div className="p-3 bg-white shadow-sm d-flex flex-column align-items-start rounded">
                   
                    <img
                      //src={image4}
                      alt="Votre Photo"
                      className="img-fluid rounded"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "150px",
                      }}
                    />

                  
                    <p className="fs-6 mt-2">Votre description ici...</p>
                  </div>
                 
                </div>
                   */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services2;
