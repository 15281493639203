import React, { useState } from "react";
import "./Feedback.css";

function Feedback() {
  const [currentPage, setCurrentPage] = useState(1);
  const feedbacksPerPage = 3; // Nombre de feedbacks par page

  // Liste de tous les feedbacks (vous pouvez obtenir cela à partir d'une source de données)
  const feedbacks = [
    {
      id: 1,
      text: "Excellent service! Very satisfied.",
      user: "John Doe",
      stars: "★★★★★",
    },
    {
      id: 2,
      text: "Very professional. Highly recommended.",
      user: "John Doe",
      stars: "★★★★",
    },
    {
      id: 3,
      text: "Fast and efficient service. Thank you very much!",
      user: "John Doe",
      stars: "★★★",
    },
    {
      id: 2,
      text: "Very professional. Highly recommended.",
      user: "John Doe",
      stars: "★★★★",
    },
    {
      id: 3,
      text: "Fast and efficient service. Thank you very much!",
      user: "Michael Johnson",
      stars: "★★★",
    },
    // Add more feedbacks here...
  ];

  // Index du premier feedback à afficher sur la page courante
  const indexOfLastFeedback = currentPage * feedbacksPerPage;
  const indexOfFirstFeedback = indexOfLastFeedback - feedbacksPerPage;
  const currentFeedbacks = feedbacks.slice(
    indexOfFirstFeedback,
    indexOfLastFeedback
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container3">
      <h2 className="titleFE">Feedback</h2>

      <div className="cardsContainer2">
        {currentFeedbacks.map((feedback) => (
          <div className="card3" key={feedback.id}>
            <div className="feedback-info1">
              <p>{feedback.text}</p>
              <hr className="line2" />
              <div className="user-details">
                <div className="user-image">
                  <img src="user.jpeg" alt="User" />
                </div>
                <p className="user-name">{feedback.user}</p>
                <div className="stars">{feedback.stars}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Pagination */}
      <div className="paginationF">
        {Array.from(
          { length: Math.ceil(feedbacks.length / feedbacksPerPage) },
          (_, i) => (
            <button key={i + 1} onClick={() => paginate(i + 1)}>
              &bull;
            </button>
          )
        )}
      </div>
    </div>
  );
}

export default Feedback;
