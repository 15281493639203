import React from "react";
import "./Popup.css";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
export default function Popup(props) {
  const { title, children, openPopup, setopenPopup } = props;

  return (
    <Dialog open={openPopup} maxWidth="xl" className="dialogwrapper">
      <DialogTitle>
        <div style={{ display: "flex" }}>
          {/* Remove the Edit profile text */}
          {/* <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
          <strong>Edit profile</strong>
        </Typography> */}
          <button
            className="root"
            style={{ color: "red" }}
            onClick={() => {
              setopenPopup(false);
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
