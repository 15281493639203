import React, { useEffect, useState } from "react";
import "./Settings.css";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";

function Settings() {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    Casetype: false,
    casename: false,
    casefolder: false,
    casenumber: false,
    ccasecountry: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ajoutez ici la logique de soumission du formulaire
  };
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [isMenuToggled, setIsMenuToggled] = useState(false);

  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuToggled(!isMenuToggled);
  };

  // Utilisez useEffect pour attacher l'événement de basculement lors du chargement du composant
  useEffect(() => {
    const toggleButton = document.getElementById("menu-toggle");
    toggleButton.addEventListener("click", toggleMenu);

    // Nettoyez l'écouteur d'événement lors du démontage du composant
    return () => {
      toggleButton.removeEventListener("click", toggleMenu);
    };
  }, []); // Le tableau vide signifie que cela ne doit s'exécuter qu'une fois après le montage

  // Ajoutez votre logique pour basculer la classe "toggled" ici
  useEffect(() => {
    const el = document.getElementById("wrapper");
    const toggleButton = document.getElementById("menu-toggle");

    const toggleWrapperClass = () => {
      el.classList.toggle("toggled");
    };

    toggleButton.addEventListener("click", toggleWrapperClass);

    return () => {
      toggleButton.removeEventListener("click", toggleWrapperClass);
    };
  }, []);
  const navigate = useNavigate();

  return (
    <div className="d-flex" id="wrapper">
      <div className="bg-white" id="sidebar-wrapper">
        <div className="sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase border-bottom">
          <Link to="/" className="navbar-logo1" style={{ color: "black" }}>
            Legal Document
          </Link>
        </div>
        <div className="list-group list-group-flush my-3">
          <a
            href="/profile"
            className="list-group-item list-group-item-action bg-transparent second-text active"
            style={{ color: "black" }}
          >
            <i className="fas fa-user me-2"></i>Profil
          </a>
          <a
            href="/"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
          >
            <i className="fas fa-home me-2"></i>Home
          </a>
          <a
            href="/services2"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
          >
            <i className="fas fa-tools me-2"></i>Services
          </a>

          <a
            href="/forum"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
          >
            <i className="fas fa-info-circle me-2"></i>Forum
          </a>
          <a
            href="/historique"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
          >
            <i className="fas fa-info-circle me-2"></i>Historique
          </a>
        </div>
        <div className="list-group mt-5">
          <a
            href="#"
            className="list-group-item list-group-item-action bg-transparent text-danger fw-bold"
          >
            <i className="fas fa-power-off me-2"></i>Logout
          </a>
        </div>
      </div>

      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4">
          <div className="d-flex align-items-center">
            <i
              className="fas fa-align-left primary-text fs-4 me-3"
              id="menu-toggle"
              style={{ color: "black" }}
            ></i>
            <h2 className="fs-2 m-0">Settings</h2>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            color="black"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "black" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                {/* Utilisez la classe "d-flex" pour aligner le texte et l'avatar horizontalement */}
                <a
                  className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Remplacez "chemin_vers_votre_avatar" par le chemin de votre image d'avatar */}
                  <img
                    //src={Avatar}
                    alt="Avatar"
                    className="me-2 rounded-circle"
                    style={{ width: "40px", height: "35px" }}
                  />
                  John Doe
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Settings
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        <div>
          <div className="container-fluid px-4">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header text-center">
                    <h2>welcome! please confirm your account settings</h2>
                    <h4>Current Case</h4>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label className="text">Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          required
                        />
                        {errors.name && (
                          <span className="error">
                            The name field is required
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="text">Case type *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Casetype"
                          required
                        />
                        {errors.Casetype && (
                          <span className="error">
                            The case type field is required
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="text">case name *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="casename"
                          required
                        />
                        {errors.casename && (
                          <span className="error">
                            The case name field is required
                          </span>
                        )}
                        {alertVariant === "danger" && (
                          <div className="text-danger">{alertMessage}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="text">case folder *</label>
                        <input
                          type="file"
                          className="form-control"
                          name="casefolder "
                          required
                        />
                        {errors.casefolder && (
                          <span className="error">
                            The case folder field is required
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="text" htmlFor="hotel">
                          case number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name=" casenumber "
                          required
                        />
                        {errors.casenumber && (
                          <span className="error">
                            The case number field is required
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="text" htmlFor="hotel">
                          case country
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name=" casecountry "
                          required
                        />
                        {errors.casecountry && (
                          <span className="error">
                            The case country field is required
                          </span>
                        )}
                      </div>
                      <div className="text-center">
                        <Link
                          to="/settingsN"
                          className="button1 btn btn-primary mr-2"
                        >
                          <i className="fa fa-save"></i> Next
                        </Link>
                      </div>
                      {showAlert && (
                        <div
                          className={`alert alert-${alertVariant}`}
                          role="alert"
                        >
                          {alertMessage}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
