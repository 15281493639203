import Navbar from "../Navbar/Navbar";

function PageNotFound() {
  return (
    <>
      <Navbar />
      <div class="container bootstrap snippets bootdey">
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right">
              <div class="col-md-10 col-md-offset-1 pull-right">
                <img
                  class="img-error"
                  src="https://bootdey.com/img/Content/fdfadfadsfadoh.png"
                />
                <h2>404 Not Found</h2>
                <p>Sorry, an error has occured, Requested page not found!</p>
                <div class="error-actions">
                  <a href="/" class="btn btn-primary btn-lg">
                    <span class="glyphicon glyphicon-arrow-left"></span>
                    Back Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PageNotFound;
