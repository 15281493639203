// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b{
    background: linear-gradient(to right, rgb(218, 191, 226), rgb(128, 0, 128));
}
.edit-profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .upload-container {
    position: relative;
  }
  
  .upload-placeholder {
    cursor: pointer;
  }
  
  .upload-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  
  .profile-image {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .btn2 {
    width: 100%;
    padding: 10px;
    background: red;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
    
  .btn3 {
    width: 100%;
    padding: 10px;
    background: blue;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-block {
    display: block;
  }

  

  
  .btn2:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/Profil/EditProfile.css"],"names":[],"mappings":"AAAA;IACI,2EAA2E;AAC/E;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,QAAQ;EACV;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,sCAAsC;IACtC,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,eAAe;IACf,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB;;;;;EAKA;IACE,yBAAyB;EAC3B","sourcesContent":[".b{\n    background: linear-gradient(to right, rgb(218, 191, 226), rgb(128, 0, 128));\n}\n.edit-profile-container {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  .upload-container {\n    position: relative;\n  }\n  \n  .upload-placeholder {\n    cursor: pointer;\n  }\n  \n  .upload-icon {\n    position: absolute;\n    right: 5px;\n    top: 5px;\n  }\n  \n  .profile-image {\n    height: 100px;\n    width: 100px;\n    object-fit: cover;\n    border-radius: 50%;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);\n    margin-bottom: 10px;\n  }\n  \n  .form-group {\n    margin-bottom: 20px;\n  }\n  \n  .form-control {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 16px;\n  }\n  \n  .btn2 {\n    width: 100%;\n    padding: 10px;\n    background: red;\n    border: none;\n    color: #fff;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n    \n  .btn3 {\n    width: 100%;\n    padding: 10px;\n    background: blue;\n    border: none;\n    color: #fff;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .btn-block {\n    display: block;\n  }\n\n  \n\n  \n  .btn2:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
