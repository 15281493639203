import "./CaseDetail.css";
import imageTop from "../assets/images/SFG-Lawyer.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp,
  faComment,
  faEye,
  faClock,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
function CaseDetail() {
  const [caseDetails, setCaseDetails] = useState(null);
  const { id } = useParams();
  const [commentText, setCommentText] = useState("");
  useEffect(() => {
    const fetchCaseDetails = async () => {
      try {
        const response = await fetch(`https://bridgetojustice.ai:8443/case/${id}`); // Remplacez l'URL par votre URL d'API
        const data = await response.json();
        setCaseDetails(data);
      } catch (error) {
        console.error("Error fetching case details:", error);
      }
    };

    fetchCaseDetails();
  }, [id]);
  const handleSubmitComment = async (caseId) => {
    const userId = localStorage.getItem("user_id");
    //e.preventDefault();
    try {
      const response = await fetch(
        `https://bridgetojustice.ai:8443/cases/comments/${caseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId, text: commentText }),
        }
      );
      if (response.ok) {
        // Le commentaire a été ajouté avec succès
        setCommentText("");
        //setError(null);
        // Vous pouvez également mettre à jour l'état de votre application pour refléter le nouveau commentaire si nécessaire
      } else {
        throw new Error("Failed to post comment");
      }
    } catch (error) {
      console.error("Error posting comment:", error);
      //setError("An error occurred while posting the comment");
    }
  };
  const navigate = useNavigate();
  const handleClickV = () => {
    // Redirige vers la page souhaitée lors du clic sur l'icône
    navigate("/forum");
  };
  const handleLike1 = async (caseId) => {
    try {
      const response = await axios.post(
        `https://bridgetojustice.ai:8443/cases/like/${caseId}`
      );
      // Mettre à jour l'état ou effectuer d'autres actions en fonction de la réponse
    } catch (error) {
      console.error("Error liking case:", error);
      // Gérer les erreurs
    }
  };
  const handleDislike1 = async (caseId) => {
    try {
      const response = await axios.post(
        `https://bridgetojustice.ai:8443/cases/dislike/${caseId}`
      );
      // Mettre à jour l'état ou effectuer d'autres actions en fonction de la réponse
    } catch (error) {
      console.error("Error disliking case:", error);
      // Gérer les erreurs
    }
  };
  return (
    <div>
      <div
        className="d-flex align-items-start"
        style={{
          minHeight: "40vh", // Adjust the height as needed
          backgroundImage: `url(${imageTop})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      ></div>
      <header className="header">
        <div className="header-left" onClick={handleClickV}>
          <FontAwesomeIcon icon={faArrowLeft} /> Go Back
        </div>
      </header>
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <div class="container d-flex align-items-center justify-content-center">
        <div class="col-md-8">
          <div class="box box-widget">
            <div class="box-header with-border">
              <div class="user-block">
                <img
                  class="img-circle"
                  src="https://bootdey.com/img/Content/avatar/avatar1.png"
                  alt="User Image"
                />
                <span class="username">
                  <a>{caseDetails?.title ?? "Loading..."}</a>
                </span>
                <span class="description">
                  Shared publicly - {caseDetails?.createdAt ?? "Loading..."}
                </span>
              </div>
            </div>
            <div class="box-body">
              {caseDetails?.image ? (
                <img
                  src={`https://bridgetojustice.ai:8443/uploads/${caseDetails.image}`}
                  alt="Case Image"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <p></p>
              )}

              <p>{caseDetails?.description ?? "Loading..."}</p>
              <FontAwesomeIcon
                icon={faThumbsUp}
                onClick={() => handleLike1(caseDetails._id)}
                style={{ color: "green" }}
              />
              <FontAwesomeIcon
                icon={faThumbsDown}
                onClick={() => handleDislike1(caseDetails._id)}
                style={{ color: "red", marginLeft: "10px" }}
              />

              <span class="pull-right text-muted">
                {caseDetails?.likes ?? 0} likes -{" "}
                {caseDetails?.comments?.length ?? 0} comments
              </span>
            </div>
            <div class="box-footer box-comments">
              {caseDetails?.comments?.map((comment) => (
                <div class="box-comment">
                  <img
                    class="img-circle img-sm"
                    src="https://bootdey.com/img/Content/avatar/avatar5.png"
                    alt="User Image"
                  />
                  /
                  <div class="comment-text">
                    <span class="username">
                      Maria Gonzales
                      <span class="text-muted pull-right">
                        {comment.createdAt}
                      </span>
                    </span>
                    <p>{comment.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div class="box-footer">
              <form>
                <img
                  class="img-responsive img-circle img-sm"
                  src="https://bootdey.com/img/Content/avatar/avatar1.png"
                  alt="Alt Text"
                />
                <div class="img-push">
                  <input
                    type="text"
                    class="form-control input-sm"
                    placeholder="Press enter to post comment"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                  <button
                    type="submit"
                    onClick={() => handleSubmitComment(caseDetails._id)}
                    class="btn btn-primary" // Ajout de la classe CSS pour styliser le bouton
                    style={{ marginTop: "10px" }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CaseDetail;
