import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white navbar-white">
      <div className="navbar-container">
        <Link to="/" className="navbar-brand">
          <img src="./logo.png" alt="Logo" className="navbar-logo" />
        </Link>

        <div className={`collapse navbar-collapse ${click ? "show" : ""}`}>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <a
                href="#pricing"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#contact"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Contact us
              </a>
            </li>
            <li className="nav-item nav-button-item">
              <button className="nav-button">
                <Link to="/login" className="nav-links2">
                  Login
                </Link>
              </button>
            </li>
            <li className="nav-item nav-button-item">
              <button className="nav-button">
                <Link to="/signup" className="nav-links2">
                  Sign up
                </Link>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex" style={{ marginTop: "-20px" }}>
        <button className="navbar-toggler" type="button" onClick={handleClick}>
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
