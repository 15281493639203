import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Slideimage.css";

function Slideimage() {
  return (
    <>
      <div className="main">
        <section className="section">
          <div className="content">
            <div className="text-container">
              <h2>Revolutionizing Legal</h2>
            </div>
            <div className="text-container">
              <h2>
                Practice Has <span className="golden-text">Never</span>
              </h2>
            </div>

            <div className="text-container">
              <h2>Been Easier Discover</h2>
            </div>
          </div>

          <div className="image-container">
            <Image src="./image 32.png" alt="Photo" fluid />
          </div>
        </section>
      </div>
    </>
  );
}

export default Slideimage;
