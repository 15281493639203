// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialogwrapper{
    padding: 2px; 
    position: absolute;
    top: 5px;

  
}
.dialogtitle {
  background-color: blue;
  color: #fff;
  padding-right: 0px;
}
.root{
  min-width: 0;
  margin: 0.5;
  border: 1px;
  background-color: white;
  border-radius: 20px;
}


  `, "",{"version":3,"sources":["webpack://./src/Popup/Popup.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,QAAQ;;;AAGZ;AACA;EACE,sBAAsB;EACtB,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,WAAW;EACX,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".dialogwrapper{\n    padding: 2px; \n    position: absolute;\n    top: 5px;\n\n  \n}\n.dialogtitle {\n  background-color: blue;\n  color: #fff;\n  padding-right: 0px;\n}\n.root{\n  min-width: 0;\n  margin: 0.5;\n  border: 1px;\n  background-color: white;\n  border-radius: 20px;\n}\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
