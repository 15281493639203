import "./Contact.css";
import React, { useState } from "react";
import axios from "axios";

function Contact() {
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    fullname: "",
    emailAddress: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://bridgetojustice.ai:8443/contact", formData);
      alert("Email envoyé avec succès");
      // Réinitialiser le formulaire après l'envoi réussi
      setFormData({
        fullname: "",
        emailAddress: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email :", error);
      alert("Une erreur est survenue lors de l'envoi de l'email");
    }
  };
  return (
    <div>
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
      />

      <div id="contact" class="contact-area section-padding">
        <div class="container2">
          <div class="section-title text-center">
            <h1>Get in Touch with us</h1>
          </div>
          <div class="row">
            <div class="col-lg-5">
              <div class="">
                <h1>Contact Details</h1>
                <p>
                  {" "}
                  If you have any questions, just fill in the contact form, and
                  we will answer you shortly.
                </p>
              </div>

              <div class="single_address" style={{ marginTop: "40px" }}>
                <i class="fa fa-phone"></i>
                <h4>Client Support</h4>
                <p>(+216) 55 285 123</p>
              </div>
              <div class="single_address">
                <i class="fa fa-envelope"></i>
                <h4>Email</h4>
                <p>Info@example.com</p>
              </div>
              <div class="single_address">
                <i class="fa fa-map-marker"></i>
                <h4>Main Office</h4>
                <p>3481 Melrose Place, Beverly Hills</p>
              </div>
            </div>
            <div class="col-lg-7" style={{}}>
              <div class="contact">
                <div className="card bg-white">
                  <div className="card-body">
                    <form className="form" onSubmit={handleSubmit}>
                      <div className="form-group row">
                        <div className="">
                          <label htmlFor="name">Full Name*</label>
                          <input
                            type="text"
                            name="fullname"
                            className="form-control"
                            placeholder="Full Name"
                            value={formData.fullname}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="">
                          <label htmlFor="email">Email Address *</label>
                          <input
                            type="email"
                            name="emailAddress"
                            className="form-control"
                            placeholder="Email adress"
                            value={formData.emailAddress}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="">
                          <label htmlFor="subject">Subject *</label>
                          <input
                            type="text"
                            name="subject"
                            className="form-control"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="">
                          <label htmlFor="message">Your Message</label>
                          <textarea
                            rows="6"
                            name="message"
                            className="form-control"
                            placeholder="Your Message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6 text-center">
                          <button
                            type="submit"
                            value="Send message"
                            name="submit"
                            id="submitButton"
                            className="btn-contact-bg" // Changez la classe ici pour appliquer le fond bleu
                            title="Submit Your Message!"
                          >
                            Submit now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
