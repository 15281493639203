import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";

function Test() {
  return (
    <Container>
      <Row className="align-items-center">
        <Col md={6}>
          <h1 className="display-4 mb-3">
            Revolutionizing Legal Practice Has Never Been Easier Discover
          </h1>

          <Button variant="primary" size="lg">
            Get started
          </Button>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end">
            <img
              src="./image 32.png"
              alt="Legal scales"
              className="img-fluid"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Test;
