import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Sidebar/Sidebar";
import "./Document.css";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  faArrowAltCircleUp,
  faArrowAltCircleDown,
} from "@fortawesome/free-solid-svg-icons";

function Document() {
  const [currentPage, setCurrentPage] = useState(1);
  const [textPerPage] = useState(4); // une phrase par page
  const text = `1.Divorce is a legal process that formally ends a marriage or union between two individuals. It is a court-ordered termination of the marital relationship, and it involves 2.addressing various issues such as the division of assets and debts, child custody and visitation rights, spousal support (alimony), and child support. 3.The reasons for divorce can vary and may include factors such as irreconcilable differences, infidelity, abuse, or other issues that have led to the breakdown of the marital relationship. 4.he legal aspects of divorce often involve negotiations, mediation, or court proceedings to reach agreements on the terms of the divorce settlement. 5. Different jurisdictions may have specific laws and procedures governing divorce, and the process can be initiated by one or both spouses. 6. a divorce to seek legal counsel to ensure their rights are protected and to navigate the complex legal processes involved. 7.Different jurisdictions may have specific grounds for divorce, which are the legal reasons that justify ending a marriage. Common grounds include 8.When spouses reach an agreement on all issues without the need for court intervention. This process is generally faster and less costly..`;
  const [showCommentPopup, setShowCommentPopup] = useState(false);

  const [comment, setComment] = useState("");

  const phrases = text.split(/\d+\./).filter(Boolean);

  const paginateText = (phrases, page, perPage) => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return phrases.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSave = async () => {
    const userId = localStorage.getItem("user_id");
    try {
      const response = await axios.post("https://bridgetojustice.ai:8443/historique", {
        text: responseData,
        user_id: userId,
      });

      if (response.status === 201) {
        console.log("Historique created successfully", response.data);
        alert("Le fichier est enregistré.");
        // Affichez un message de succès ou mettez à jour l'état si nécessaire
      } else {
        console.error("Failed to create historique");
        // Affichez un message d'erreur ou gérez l'erreur selon vos besoins
      }
    } catch (error) {
      console.error("Error creating historique:", error);
      // Affichez un message d'erreur ou gérez l'erreur selon vos besoins
    }
  };

  const handleCommentIconClick = () => {
    setShowCommentPopup(true);
  };

  const handleCommentSubmit = () => {
    // Vous pouvez ajouter ici la logique pour enregistrer le commentaire, par exemple, l'envoyer à un backend
    alert("Comment submitted: " + comment);
    // Fermer la popup et effacer le commentaire
    setShowCommentPopup(false);
    setComment("");
  };

  const [clickedUpIconIndex, setClickedUpIconIndex] = useState(null);
  const [clickedDownIconIndex, setClickedDownIconIndex] = useState(null);

  // Gestion du clic sur l'icône Up
  const handleUpIconClick = (index) => {
    setClickedUpIconIndex(index === clickedUpIconIndex ? null : index);
    setClickedDownIconIndex(null); // Assurez-vous que l'autre icône est définie sur null
  };

  // Gestion du clic sur l'icône Down
  const handleDownIconClick = (index) => {
    setClickedDownIconIndex(index === clickedDownIconIndex ? null : index);
    setClickedUpIconIndex(null); // Assurez-vous que l'autre icône est définie sur null
  };
  const [responseData, setResponseData] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const responseDataFromURL = searchParams.get("responseData");

  useEffect(() => {
    // Supprimer les caractères de saut de ligne de responseDataFromURL
    const formattedResponseData = responseDataFromURL
      ? responseDataFromURL.replace(/\n/g, " ")
      : "";
    setResponseData(formattedResponseData);
  }, [responseDataFromURL]);
  const [isMenuToggled, setIsMenuToggled] = useState(false);

  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuToggled(!isMenuToggled);
  };

  // Utilisez useEffect pour attacher l'événement de basculement lors du chargement du composant
  useEffect(() => {
    const toggleButton = document.getElementById("menu-toggle");
    toggleButton.addEventListener("click", toggleMenu);

    // Nettoyez l'écouteur d'événement lors du démontage du composant
    return () => {
      toggleButton.removeEventListener("click", toggleMenu);
    };
  }, []); // Le tableau vide signifie que cela ne doit s'exécuter qu'une fois après le montage

  // Ajoutez votre logique pour basculer la classe "toggled" ici
  useEffect(() => {
    const el = document.getElementById("wrapper");
    const toggleButton = document.getElementById("menu-toggle");

    const toggleWrapperClass = () => {
      el.classList.toggle("toggled");
    };

    toggleButton.addEventListener("click", toggleWrapperClass);

    return () => {
      toggleButton.removeEventListener("click", toggleWrapperClass);
    };
  }, []);
  const [profileData, setProfileData] = useState(null);
  const [ProfileDataToEdit, setProfileDataToEdit] = useState(null);
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    // Fonction pour récupérer les données du profil par ID
    const fetchProfileData = async () => {
      try {
        // Remplacez l'URL par l'URL de votre API GET par ID
        const response = await fetch(
          `https://bridgetojustice.ai:8443/profile/${user_id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch profile data");
        }
        const data = await response.json();
        setProfileData(data.user);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    // Appelez la fonction fetchProfileData une fois lorsque le composant est monté
    fetchProfileData();
  }, []);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    window.location.href = "/login";
  };
  return (
    <div className="d-flex" id="wrapper">
      <div className="bg-white" id="sidebar-wrapper">
        <div className="sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase border-bottom">
          <Link
            to="/"
            className="navbar-logo1"
            style={{
              color: "black",
              fontSize: "1.2rem",
              justifyContent: "center",
            }}
          >
            Legal Document
          </Link>
        </div>
        <div className="list-group list-group-flush my-3">
          <a
            href="/profile"
            className="list-group-item list-group-item-action bg-transparent second-text active"
            style={{ color: "black" }}
          >
            <i className="fas fa-user me-2"></i>Profil
          </a>

          <a
            href="/services2"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-tools me-2"></i>Services
          </a>

          <a
            href="/forum"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Forum
          </a>
          <a
            href="/historique"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Historique
          </a>
        </div>
        <div className="list-group mt-5">
          <a
            onClick={logout}
            className="list-group-item list-group-item-action bg-transparent text-danger fw-bold"
          >
            <i className="fas fa-power-off me-2"></i>Logout
          </a>
        </div>
      </div>

      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4">
          <div className="d-flex align-items-center">
            <i
              className="fas fa-align-left primary-text fs-4 me-3"
              id="menu-toggle"
              style={{ color: "black" }}
            ></i>
            <h2 className="fs-2 m-0">Document</h2>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            color="black"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "black" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                {/* Utilisez la classe "d-flex" pour aligner le texte et l'avatar horizontalement */}
                <a
                  className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Remplacez "chemin_vers_votre_avatar" par le chemin de votre image d'avatar */}
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnfAxGV-fZxGL9elM_hQ2tp7skLeSwMyUiwo4lMm1zyA&s"
                    alt="Avatar"
                    className="me-2 rounded-circle"
                    style={{ width: "40px", height: "35px" }}
                  />
                  {profileData ? (
                    <p style={{ color: "black" }}> {profileData.username}</p>
                  ) : (
                    <p style={{ color: "black" }}>Loading...</p>
                  )}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/profile">
                      Profile
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" onClick={logout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        <div className="container-fluid px-4">
          <div className="main-content">
            <div className="centered-card">
              <div className="download-new-file-container">
                <Link to="/settingsN">
                  <button className="download-new-file-button">
                    Upload New File
                  </button>
                </Link>
              </div>
              <div className="card6">
                <div className="card-content6">
                  <h2 style={{ fontWeight: "bold" }}>Document</h2>
                  <p>{responseData}</p>
                  {/* {paginateText(phrases, currentPage, textPerPage).map(
                (phrase, index) => (
                  <div key={index} className="phrase">
                    <p>
                      <strong>{currentPage + index}.</strong>
                      <span title={phrase.trim()}>{phrase.trim()}</span>
                      <FontAwesomeIcon
                        icon={faArrowAltCircleUp}
                        style={{
                          marginLeft: "20px",
                          color:
                            clickedUpIconIndex === currentPage + index
                              ? "green"
                              : "",
                        }}
                        onClick={() => handleUpIconClick(currentPage + index)}
                      />
                      <FontAwesomeIcon
                        icon={faArrowAltCircleDown}
                        style={{
                          marginLeft: "5px",
                          color:
                            clickedDownIconIndex === currentPage + index
                              ? "red"
                              : "",
                        }}
                        onClick={() => handleDownIconClick(currentPage + index)}
                      />
                      <FontAwesomeIcon
                        icon={faComment}
                        onClick={handleCommentIconClick}
                        style={{ marginLeft: "5px" }}
                      />
                    </p>
                  </div>
                )
              )}*/}
                </div>
                {/*<div className="pagination2">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    &lt; Prev
                  </button>
                  <span>Page {currentPage}</span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      phrases.length <= currentPage * textPerPage ||
                      currentPage === Math.ceil(phrases.length / textPerPage)
                    }
                  >
                    Next &gt;
                  </button>
                  </div>*/}
              </div>
              <div className="buttons">
                <button onClick={handleSave}>Save</button>
                <button>Download</button>
              </div>
            </div>
          </div>
          {showCommentPopup && (
            <div className="comment-popup">
              <button
                className="close-button1"
                onClick={() => setShowCommentPopup(false)}
                style={{
                  backgroundColor: "red",
                  marginTop: "-13px",
                  width: "20px",
                }}
              >
                ×
              </button>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter your comment..."
                style={{ marginTop: "24px" }}
              />
              <button
                onClick={handleCommentSubmit}
                className="bCancel"
                style={{ backgroundColor: "red" }}
              >
                Cancel
              </button>

              <button onClick={handleCommentSubmit}>Submit</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Document;
