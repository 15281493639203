import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import des icônes des yeux
import "./Signup.css";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup() {
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== repeatPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas");
      return;
    }

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setErrorMessage(
        "Le mot de passe doit contenir au moins 8 caractères, au moins un chiffre et au moins une lettre majuscule."
      );
      return;
    }

    try {
      const response = await fetch("https://bridgetojustice.ai:8443/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(
          "Registration successful! Redirecting to the login page..."
        );
        navigate("/login");
      } else {
        toast.error(data.error || "Account creation failed.");
        console.error("Signup failed");
      }
    } catch (error) {
      toast.error("An error occurred.");
      console.error("Error:", error);
    }
  };

  const signwithgoogle = () => {
    window.open("https://bridgetojustice.ai:8443/auth/google/callback", "_self");
  };
  const signwithfacebook = () => {
    window.open("https://bridgetojustice.ai:8443/auth/facebook/callback", "_self");
  };

  return (
    <>
      <Navbar />
      <div className="Auth-form-container">
        <div className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Register</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group mt-3">
                <label style={{ color: "black" }}>Full Name</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter name"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label style={{ color: "black" }}>Email address</label>
                <input
                  type="email"
                  className="form-control mt-1"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label style={{ color: "black" }}>Password</label>
                <div className="password-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control mt-1"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <label style={{ color: "black" }}>Repeat Password</label>
                <input
                  type="password"
                  className="form-control mt-1"
                  placeholder="Repeat password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </div>
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <div className="mt-3 d-flex flex-column align-items-center">
                <div className="mb-3">
                  <button
                    className="btn btnlogin btn-primary border-white"
                    type="submit"
                    style={{ backgroundColor: "#0B2139" }}
                  >
                    SignUp
                  </button>
                </div>
                <p className="text-center">
                  Already have an account? <a href="/login">Login</a>
                </p>
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
