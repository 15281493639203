import React from "react";
import "./Pricing.css";
import { FaCog } from "react-icons/fa";
import { FaMedal } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function Pricing() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/signup"); // Remplacez "/signup" par l'URL de la page de signup
  };
  return (
    <div className="container2" id="pricing">
      <h2
        className="titleP"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        Pricing
      </h2>
      <div className="cardsContainer1">
        <div className="card2">
          <div className="icon-container">
            <div className="circle">
              <FaStar className="icon" style={{ color: "#0B2139" }} />
            </div>
          </div>
          <h4 style={{ marginTop: "15px", color: "#0B2139" }}>
            Silver Package
          </h4>
          <div className="line"></div>
          <div className="item_group">
            <div className="item">
              <i className="fas fa-check"></i>
              <p>5 process document / day</p>
            </div>
            <div className="item">
              <i className="fas fa-check"></i>
              <p>5 downlaod file / day</p>
            </div>
          </div>

          <div className="line"></div>
          <div className="price">
            <p>Free</p>
            <button className="button2" onClick={handleNavigate}>
              Buy
            </button>
          </div>
        </div>
        <div className="card2">
          <div className="icon-container">
            <div className="circle">
              <FaMedal className="icon" style={{ color: "#0B2139" }} />
            </div>
          </div>
          <h4 style={{ marginTop: "15px", color: "#0B2139" }}>
            Golden Package
          </h4>
          <div className="line"></div>
          <div className="item_group">
            <div className="item">
              <i className="fas fa-check"></i>
              <p>50 process document / day</p>
            </div>
            <div className="item">
              <i className="fas fa-check"></i>
              <p>50 downlaod file / day</p>
            </div>
            <div className="item">
              <i className="fas fa-check"></i>
              <p>priority support</p>
            </div>
          </div>
          <div className="line"></div>
          <div className="price">
            <p>$100</p>
            <button
              className="button2"
              style={{ pointerEvents: "none", opacity: "0.5" }}
            >
              Buy
            </button>
          </div>
        </div>
        <div className="card2">
          <div className="icon-container">
            <div className="circle">
              <FaCog className="icon" style={{ color: "#0B2139" }} />
            </div>
          </div>
          <h4 style={{ marginTop: "15px", color: "#0B2139" }}>
            Premium Package
          </h4>
          <div className="line"></div>
          <div className="item_group">
            <div className="item">
              <i className="fas fa-check"></i>
              <p>illimit  process document / day</p>
            </div>
            <div className="item">
              <i className="fas fa-check"></i>
              <p>illimit  downlaod file / day</p>
            </div>
            <div className="item">
              <i className="fas fa-check"></i>
              <p>Priority Support</p>
            </div>
            <div className="item">
              <i className="fas fa-check"></i>

              <p>api integration</p>
            </div>
          </div>
          <div className="line"></div>
          <div className="price">
            <p> $200</p>
            <button
              className="button2"
              style={{ pointerEvents: "none", opacity: "0.5" }}
            >
              Buy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
