import React, { useEffect, useState } from "react";
import "../Settings/Settings.css";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
function SettingsN() {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({
    Plainttifnames: false,
    Defendantnames: false,
    Synopsisfile: false,
    Complaintfile: false,
    Demurrerfile: false,
    DemurrerMosfile: false,
  });
  const [formData, setFormData] = useState({
    case_synopsis: "",
    current_complaint: "",
    current_demurrer: "",
    demurrer_memorandum: "",
    title: "",
    count_title: "",
    state: "",
    country: "",
    demurrer_MoS: "",
    demurrer: "",
    count_number: null,
    plaintiff: "",
    element_number: null,
    element_title: "",
    element_description: "",
    argument_title: "",
    argument_description: "",
    complaint_paragraphs: "",
    complaint_sections: "",
    latest_demurrer_memorandum: "",
    demurrer_section_response: "",
    defendant: "",
    defendant_1: "",
    defendant_2: "",
    defendant_3: "",
    defendant_4: "",
    defendant_name: "",
    demurrer_section: "",
    context: "",
    pertinent_facts: "",
    history: "",
    input: "",
  });
  const [template, settemplate] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Hook useNavigate pour la navigation
  const handleChange2 = (event) => {
    const { value } = event.target;
    settemplate(value);
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Commencer le chargement
    // Perform form validation here if needed
    // Example: Check if plaintiff and defendant are not empty

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        template: template,
        context_variables: formData,
      }),
    };

    try {
      const response = await fetch(
        "https://bridgetojustice.ai:5001/generate_response",
        requestOptions
      );
      const data = await response.json();

      setResponse(data); // Assuming the response is a JSON object
      setIsLoading(false); // Fin du chargement
      // Naviguer vers la page Document avec les données passées dans l'URL
      navigate(
        `/document?responseData=${encodeURIComponent(
          JSON.stringify(data.response ? data.response.replace(/\n/g, " ") : "")
        )}`
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false); // Fin du chargement
    }
  };
  const [profileData, setProfileData] = useState(null);
  const [ProfileDataToEdit, setProfileDataToEdit] = useState(null);
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    // Fonction pour récupérer les données du profil par ID
    const fetchProfileData = async () => {
      try {
        // Remplacez l'URL par l'URL de votre API GET par ID
        const response = await fetch(
          `https://bridgetojustice.ai:8443/profile/${user_id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch profile data");
        }
        const data = await response.json();
        setProfileData(data.user);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    // Appelez la fonction fetchProfileData une fois lorsque le composant est monté
    fetchProfileData();
  }, []);
  const [isMenuToggled, setIsMenuToggled] = useState(false);

  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuToggled(!isMenuToggled);
  };

  // Utilisez useEffect pour attacher l'événement de basculement lors du chargement du composant
  useEffect(() => {
    const toggleButton = document.getElementById("menu-toggle");
    toggleButton.addEventListener("click", toggleMenu);

    // Nettoyez l'écouteur d'événement lors du démontage du composant
    return () => {
      toggleButton.removeEventListener("click", toggleMenu);
    };
  }, []); // Le tableau vide signifie que cela ne doit s'exécuter qu'une fois après le montage

  // Ajoutez votre logique pour basculer la classe "toggled" ici
  useEffect(() => {
    const el = document.getElementById("wrapper");
    const toggleButton = document.getElementById("menu-toggle");

    const toggleWrapperClass = () => {
      el.classList.toggle("toggled");
    };

    toggleButton.addEventListener("click", toggleWrapperClass);

    return () => {
      toggleButton.removeEventListener("click", toggleWrapperClass);
    };
  }, []);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    window.location.href = "/login";
  };
  return (
    <div className="d-flex" id="wrapper">
      <div className="bg-white" id="sidebar-wrapper">
        <div className="sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase border-bottom">
          <Link
            to="/"
            className="navbar-logo1"
            style={{
              color: "black",
              fontSize: "1.2rem",
              justifyContent: "center",
            }}
          >
            Legal Document
          </Link>
        </div>
        <div className="list-group list-group-flush my-3">
          <a
            href="/profile"
            className="list-group-item list-group-item-action bg-transparent second-text active"
            style={{ color: "black" }}
          >
            <i className="fas fa-user me-2"></i>Profil
          </a>

          <a
            href="/services2"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-tools me-2"></i>Services
          </a>

          <a
            href="/forum"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Forum
          </a>
          <a
            href="/historique"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Historique
          </a>
        </div>
        <div className="list-group mt-5">
          <a
            onClick={logout}
            className="list-group-item list-group-item-action bg-transparent text-danger fw-bold"
          >
            <i className="fas fa-power-off me-2"></i>Logout
          </a>
        </div>
      </div>

      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4">
          <div className="d-flex align-items-center">
            <i
              className="fas fa-align-left primary-text fs-4 me-3"
              id="menu-toggle"
              style={{ color: "black" }}
            ></i>
            <h2 className="fs-2 m-0">Settings</h2>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            color="black"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "black" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                {/* Utilisez la classe "d-flex" pour aligner le texte et l'avatar horizontalement */}
                <a
                  className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Remplacez "chemin_vers_votre_avatar" par le chemin de votre image d'avatar */}
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnfAxGV-fZxGL9elM_hQ2tp7skLeSwMyUiwo4lMm1zyA&s"
                    alt="Avatar"
                    className="me-2 rounded-circle"
                    style={{ width: "40px", height: "35px" }}
                  />
                  {profileData ? (
                    <p style={{ color: "black" }}> {profileData.username}</p>
                  ) : (
                    <p style={{ color: "black" }}>Loading...</p>
                  )}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Settings
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        <div className="container-fluid px-4">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header text-center">
                  <h2>welcome! please confirm your account settings</h2>
                  <h4>Current Case</h4>
                </div>
                {isLoading && (
                  <div className="loading-icon">
                    <div className="spinner"></div>
                  </div>
                )}
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="text">Choose a Template*</label>
                      <select
                        className="form-control"
                        name="template"
                        value={template}
                        onChange={handleChange2}
                        required
                      >
                        <option value="">Select an option</option>
                        <option value="initial_prompt_template">
                          initial_prompt_template
                        </option>
                        <option value="element_finder_human_template">
                          element_finder_human_template
                        </option>
                        <option value="demurrer_parser_system_template">
                          demurrer_parser_system_template
                        </option>
                        <option value="demurrer_parser_human_template">
                          demurrer_parser_human_template
                        </option>
                        <option value="demurred_argument_to_case_para_compare_human_template">
                          demurred_argument_to_case_para_compare_human_template
                        </option>
                        <option value="demurred_argument_to_case_para_compare_system_template">
                          demurred_argument_to_case_para_compare_system_template
                        </option>
                        <option value="demurred_count_argument_finder_human_template">
                          demurred_count_argument_finder_human_template
                        </option>
                        <option value="demurred_count_argument_finder_system_template">
                          demurred_count_argument_finder_system_template
                        </option>
                        <option value="demurred_count_finder_human_template">
                          demurred_count_finder_human_template
                        </option>
                        <option value="demurred_count_finder_system_template">
                          demurred_count_finder_system_template
                        </option>
                        <option value="demurred_precedent_case_finder_human_template">
                          demurred_precedent_case_finder_human_template
                        </option>
                        <option value="demurred_precedent_case_finder_system_template">
                          demurred_precedent_case_finder_system_template
                        </option>
                        <option value="demurrer_comprehensive_response_human_template">
                          demurrer_comprehensive_response_human_template
                        </option>
                        <option value="demurrer_comprehensive_response_template">
                          demurrer_comprehensive_response_template
                        </option>
                        <option value="demurrer_per_section_response_human_template">
                          demurrer_per_section_response_human_template
                        </option>
                        <option value="demurrer_per_section_response_template">
                          demurrer_per_section_response_template
                        </option>
                        <option value="generic_legal_expert_system_template_no_case_data">
                          generic_legal_expert_system_template_no_case_data
                        </option>
                        <option value="section_applicability_human_template">
                          section_applicability_human_template
                        </option>
                        <option value="section_applicability_system_template">
                          section_applicability_system_template
                        </option>
                        <option value="section_creation_conversation_template">
                          section_creation_conversation_template
                        </option>
                      </select>
                      {errors.selected_option && (
                        <span className="error">Please select an option</span>
                      )}
                    </div>
                    {/* Afficher les champs spécifiques à chaque template */}
                    {template === "initial_prompt_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> current_complaint*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_complaint"
                            value={formData.current_complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">current_demurrer *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_demurrer"
                            value={formData.current_demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The name field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer_memorandum *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_memorandum"
                            value={formData.demurrer_memorandum}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The name field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à initial_prompt_template */}
                      </>
                    )}
                    {template === "element_finder_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> current_complaint*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_complaint"
                            value={formData.current_complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">current_demurrer *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_demurrer"
                            value={formData.current_demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The name field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer_memorandum *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_memorandum"
                            value={formData.demurrer_memorandum}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The name field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">state *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The state field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">country *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The country field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template === "demurrer_parser_system_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        {/* Autres champs spécifiques à demurrer_parser_system_template */}
                      </>
                    )}
                    {template === "demurrer_parser_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label className="text">demurrer *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The name field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à initial_prompt_template */}
                      </>
                    )}
                    {template ===
                      "demurred_argument_to_case_para_compare_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> defendant*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="count_number"
                            value={formData.count_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="element_number"
                            value={formData.element_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_title"
                            value={formData.element_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_description"
                            value={formData.element_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">argument_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="argument_title"
                            value={formData.argument_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The argument_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">argument_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="argument_description"
                            value={formData.argument_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The argument_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint_paragraphs *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint_paragraphs"
                            value={formData.complaint_paragraphs}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint_paragraphs field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "demurred_argument_to_case_para_compare_system_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> defendant*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="count_number"
                            value={formData.count_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="element_number"
                            value={formData.element_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_title"
                            value={formData.element_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_description"
                            value={formData.element_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">argument_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="argument_title"
                            value={formData.argument_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The argument_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">argument_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="argument_description"
                            value={formData.argument_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The argument_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint_paragraphs *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint_paragraphs"
                            value={formData.complaint_paragraphs}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint_paragraphs field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">plaintiff*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff"
                            value={formData.plaintiff}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              plaintiff field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "demurred_count_argument_finder_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> defendant*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="count_number"
                            value={formData.count_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="element_number"
                            value={formData.element_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_title"
                            value={formData.element_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_description"
                            value={formData.element_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint"
                            value={formData.complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint field is required
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">plaintiff*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff"
                            value={formData.plaintiff}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              plaintiff field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "demurred_count_argument_finder_system_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> defendant*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="count_number"
                            value={formData.count_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="element_number"
                            value={formData.element_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_title"
                            value={formData.element_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_description"
                            value={formData.element_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint"
                            value={formData.complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint field is required
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">plaintiff*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff"
                            value={formData.plaintiff}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              plaintiff field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template === "demurred_count_finder_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> defendant*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="count_number"
                            value={formData.count_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="element_number"
                            value={formData.element_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_title"
                            value={formData.element_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_description"
                            value={formData.element_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint"
                            value={formData.complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint field is required
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">plaintiff*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff"
                            value={formData.plaintiff}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              plaintiff field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template === "demurred_count_finder_system_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> defendant*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="count_number"
                            value={formData.count_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="element_number"
                            value={formData.element_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_title"
                            value={formData.element_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_description"
                            value={formData.element_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint"
                            value={formData.complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint field is required
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">plaintiff*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff"
                            value={formData.plaintiff}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              plaintiff field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "demurred_precedent_case_finder_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> defendant*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="count_number"
                            value={formData.count_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="element_number"
                            value={formData.element_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_title"
                            value={formData.element_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_description"
                            value={formData.element_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint"
                            value={formData.complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint field is required
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">plaintiff*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff"
                            value={formData.plaintiff}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              plaintiff field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">argument_title*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="argument_title"
                            value={formData.argument_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              argument_titlefield is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">argument_description*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="argument_description"
                            value={formData.argument_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              argument_description field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "demurred_precedent_case_finder_system_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> defendant*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The Defendant names field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="count_number"
                            value={formData.count_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">count_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="count_title"
                            value={formData.count_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The count_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_number *</label>
                          <input
                            type="number"
                            className="form-control"
                            name="element_number"
                            value={formData.element_number}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_number field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_title *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_title"
                            value={formData.element_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_title field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">element_description *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="element_description"
                            value={formData.element_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The element_description field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint"
                            value={formData.complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint field is required
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="text">demurrer_MoS *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">plaintiff*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff"
                            value={formData.plaintiff}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              plaintiff field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">argument_title*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="argument_title"
                            value={formData.argument_title}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              argument_titlefield is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">argument_description*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="argument_description"
                            value={formData.argument_description}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              argument_description field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "demurrer_comprehensive_response_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">defendant *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_1 *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_1"
                            value={formData.defendant_1}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_2 *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_2"
                            value={formData.defendant_2}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_3 *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_3"
                            value={formData.defendant_3}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_4 *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_4"
                            value={formData.defendant_4}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> demurrer_MoS*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">
                            latest_demurrer_memorandum *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="latest_demurrer_memorandum"
                            value={formData.latest_demurrer_memorandum}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The latest_demurrer_memorandum field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">
                            demurrer_section_response *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_section_response"
                            value={formData.demurrer_section_response}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_section_response field is required
                            </span>
                          )}
                        </div>

                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "demurrer_comprehensive_response_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">defendant *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">plaintiff *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff"
                            value={formData.plaintiff}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> demurrer_MoS*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_MoS"
                            value={formData.demurrer_MoS}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The demurrer_MoS field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer"
                            value={formData.demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">
                            latest_demurrer_memorandum *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="latest_demurrer_memorandum"
                            value={formData.latest_demurrer_memorandum}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The latest_demurrer_memorandum field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">
                            demurrer_section_response *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_section_response"
                            value={formData.demurrer_section_response}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_section_response field is required
                            </span>
                          )}
                        </div>

                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "demurrer_per_section_response_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">demurrer_section *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_section"
                            value={formData.demurrer_section}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> context*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="context"
                            value={formData.context}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The context field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">pertinent_facts *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="pertinent_facts"
                            value={formData.pertinent_facts}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The pertinent_facts field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">defendant *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The defendant field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à initial_prompt_template */}
                      </>
                    )}
                    {template === "demurrer_per_section_response_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The case_synopsis field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">defendant *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant"
                            value={formData.defendant}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_1 *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_1"
                            value={formData.defendant_1}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_2 *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_2"
                            value={formData.defendant_2}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_3 *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_3"
                            value={formData.defendant_3}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_4 *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_4"
                            value={formData.defendant_4}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> current_complaint*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_complaint"
                            value={formData.current_complaint}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The current_complaint field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">current_demurrer *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="current_demurrer"
                            value={formData.current_demurrer}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The current_demurrer field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer_memorandum *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_memorandum"
                            value={formData.demurrer_memorandum}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_memorandum field is required
                            </span>
                          )}
                        </div>

                        {/* Autres champs spécifiques à element_finder_human_template */}
                      </>
                    )}
                    {template ===
                      "generic_legal_expert_system_template_no_case_data" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> state*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The state field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">country *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The country field is required
                            </span>
                          )}
                        </div>

                        {/* Autres champs spécifiques à initial_prompt_template */}
                      </>
                    )}
                    {template === "section_applicability_human_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> state*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The state field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">country *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The country field is required
                            </span>
                          )}
                        </div>

                        {/* Autres champs spécifiques à initial_prompt_template */}
                      </>
                    )}
                    {template === "section_applicability_system_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> plaintiff_name*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plaintiff_name"
                            value={formData.plaintiff_name}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The plaintiff_name field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">defendant_name *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="defendant_name"
                            value={formData.defendant_name}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The defendant_name field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">demurrer_section *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="demurrer_section"
                            value={formData.demurrer_section}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The demurrer_section field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">complaint_sections *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="complaint_sections"
                            value={formData.complaint_sections}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The complaint_sections field is required
                            </span>
                          )}
                        </div>
                        {/* Autres champs spécifiques à initial_prompt_template */}
                      </>
                    )}
                    {template === "section_creation_conversation_template" && (
                      <>
                        <div className="form-group">
                          <label className="text">case_synopsis *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="case_synopsis"
                            value={formData.case_synopsis}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="text"> history*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="history"
                            value={formData.history}
                            onChange={handleChange}
                            required
                          />
                          {errors.Casetype && (
                            <span className="error">
                              The history field is required
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text">input *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="input"
                            value={formData.input}
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <span className="error">
                              The input field is required
                            </span>
                          )}
                        </div>

                        {/* Autres champs spécifiques à initial_prompt_template */}
                      </>
                    )}
                    {/* Champs communs à tous les templates */}

                    {/*<div className="form-group">
                      <label className="text">Synopsis file *</label>
                      <input
                        type="file"
                        className="form-control"
                        name="Synopsisfile"
                        required
                      />
                      {errors.casename && (
                        <span className="error">
                          The Synopsis file field is required
                        </span>
                      )}
                      {alertVariant === "danger" && (
                        <div className="text-danger">{alertMessage}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="text">Complaint file *</label>
                      <input
                        type="file"
                        className="form-control"
                        name="Complaintfile "
                        required
                      />
                      {errors.casefolder && (
                        <span className="error">
                          The Complaint file field is required
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="text" htmlFor="hotel">
                        Demurrer file
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=" Demurrerfile "
                        required
                      />
                      {errors.casenumber && (
                        <span className="error">
                          The Demurrer file field is required
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="text" htmlFor="hotel">
                        Demurrer Mos file
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=" DemurrerMosfile "
                        required
                      />
                      {errors.casecountry && (
                        <span className="error">
                          The Demurrer Mos file field is required
                        </span>
                      )}
                    </div>*/}
                    <div className="text-center">
                      <div className="button-container">
                        <button
                          type="submit"
                          className="button1 btn btn-primary mr-2"
                        >
                          <i className="fa fa-save"></i> Start
                        </button>

                        <Link
                          to="/settings"
                          className="button1 btn btn-primary mr-2"
                        >
                          <i className="fa fa-save"></i> Previous
                        </Link>
                      </div>
                    </div>

                    {showAlert && (
                      <div
                        className={`alert alert-${alertVariant}`}
                        role="alert"
                      >
                        {alertMessage}
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsN;
