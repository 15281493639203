import React, { useState, useEffect } from "react";
import { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./EditProfile.css";
import { Col, Container, Image, Row, Form, Button } from "react-bootstrap";
import image from "../assets/images/gettyimages-1334476315-612x612.jpg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
const EditProfile = ({ profileData, setProfileDataToEdit, setOpenPopup }) => {
  const [showPopup, setShowPopup] = useState(true); // État pour contrôler l'affichage du popup

  const handleCancel = () => {
    setOpenPopup(false); // Fermer le popup lorsque le bouton "Cancel" est cliqué
  };
  const inputRef = useRef(null);
  const [user, setUser] = useState({
    name: "",
    photo: "",
    email: "",
  });

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const uploadImage = () => {
    inputRef.current.click();
  };
  const [username, setName] = useState("");
  const [email, setEmail] = useState("");
  const [job, setJob] = useState("");
  const [address, setAddress] = useState("");
  useEffect(() => {
    if (profileData) {
      setName(profileData.username);
      setEmail(profileData.email);
      setJob(profileData.job);
      setAddress(profileData.address);
    }
  }, [profileData]);
  const handleSubmit = async () => {
    const userId = localStorage.getItem("user_id");

    try {
      const response = await fetch(
        `https://bridgetojustice.ai:8443/edit-profile/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            email: email,
            job: job,
            address: address,
          }),
        }
      );

      if (response.ok) {
        console.log("Profil utilisateur mis à jour avec succès !");

        toast.success("User profile updated successfully!");
        setOpenPopup(false);
        // Redirection ou affichage d'un message de succès
      } else {
        console.error("Échec de la mise à jour du profil.");
        // Affichage d'un message d'erreur
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil :", error);
      toast.error("Failed to update user profile.");
      // Affichage d'un message d'erreur
    }
  };

  return (
    <div>
      <div>
        {/* Circular Image */}

        <Image
          src={image}
          alt="Profile"
          roundedCircle
          style={{ width: "150px", height: "150px", marginBottom: "20px" }}
        />
      </div>

      {/* Title */}
      <div>
        <h2>{username}</h2>
      </div>

      {/* Small Description */}

      {/* Input Line */}
      <div>
        <Form.Group controlId="formInput" className="mb-3">
          <Form.Label style={{ color: "black" }}>Name</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formInput" className="mb-3">
          <Form.Label style={{ color: "black" }}>Email</Form.Label>
          <Form.Control
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" className="mr-2" onClick={handleSubmit}>
          Update
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditProfile;
