import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { GoogleLogin } from "react-google-login"; // Import GoogleLogin component
import "./Login.css";
import { FcGoogle } from "react-icons/fc";
import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialGoogle } from "reactjs-social-login";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://bridgetojustice.ai:8443/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }), // Assurez-vous que les variables email et password sont définies
      });

      if (response.ok) {
        const data = await response.json();
        const { token } = data;

        // Stocker le token dans le localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("user_id", data.user._id);

        // Rediriger l'utilisateur vers une autre page ou effectuer l'action appropriée
        navigate("/services2");
      } else {
        // Afficher un toast en cas de connexion échouée
        const errorData = await response.json();
        toast.error(errorData.message);
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Error:", error);
      // Afficher un toast en cas d'erreur
      toast.error("Error logging in");
      setError("Error logging in");
    }
  };

  const handleResolve = ({ provider, data }) => {
    console.log(provider, data);
    navigate("/services"); // Redirect to "/services" page
  };
  const loginwithgoogle = () => {
    window.open("https://bridgetojustice.ai:8443/auth/google/callback", "_self");
  };
  const loginwithfacebook = () => {
    window.open("https://bridgetojustice.ai:8443/auth/facebook/callback", "_self");
  };

  return (
    <>
      <Navbar />
      <div className="Auth-form-container">
        <div className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Login</h3>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="imgs">
              <div className="container-image">
                <img src="login.svg.png" alt="login" className="login" />
              </div>
            </div>

            <div className="form-group mt-3">
              <label style={{ color: "#0B2139" }}>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label style={{ color: "#0B2139" }}>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="mt-3 d-flex flex-column align-items-center">
              <div className="mb-3">
                <button
                  className="btn btnlogin btn-primary border-white"
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#0B2139" }}
                >
                  Login
                </button>
              </div>
              <a href="https://bridgetojustice.ai/signup" style={{ color: "white" }}>
                You don't have an account? Sign up
              </a>

              <div className="justify-content-center">
                <button
                  className="btn btn-outline-light mr-2"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid #0B2139",
                    whiteSpace: "nowrap",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={loginwithgoogle}
                >
                  <FcGoogle style={{ marginRight: "5px" }} />
                  Login with Google
                </button>

                <button
                  className="btn btn-outline-light"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid #0B2139",
                    whiteSpace: "nowrap",
                    width: "100%",
                    height: "100%",
                    marginTop: "10px",
                  }}
                  onClick={loginwithfacebook}
                >
                  <FaFacebook style={{ marginRight: "5px", color: "blue" }} />
                  Login with Facebook
                </button>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
