import React, { Component } from "react";
import { render } from "react-dom";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Home from "./Home/Home";
import Feature from "./Features/Feature";
import Login from "./Login/Login";
import Signup from "./SignUp/Signup";
import Contact from "./Contact/Contact";
import Pricing from "./Pricing/Pricing";
import Sidebar from "./Sidebar/Sidebar";
import Settings from "./Settings/Settings";
import Services from "./Services/Services";
import Profile from "./Profil/Profil";
import Historique from "./Historique/Historique";
import EditProfile from "./Profil/EditProfil";
import SettingsN from "./Settings2/SettingsN";
import Document from "./Document/Document";
import Payment from "./Payment/Payment";
import Forum from "./forum/Forum";

import Payment2 from "./Payment/Payment2";
import Services2 from "./Services/Services2";
import PrivateRoute from "./PrivateRoutes/PrivateRoute";
import Test from "./Home/test";
import CaseDetail from "./forum/CaseDetail";
import PageNotFound from "./PagesErreurs/404-page";
import InternalServerPage from "./PagesErreurs/500-page";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}></Route>
          <Route path="/settings" element={<Settings />} />
          <Route path="/settingsN" element={<SettingsN />} />
          <Route path="/document" element={<Document />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-Profile" element={<EditProfile />} />
          <Route path="/historique" element={<Historique />} />
          <Route path="/forum" element={<Forum />} />
          <Route path="/payment2" element={<Payment2 />} />
          <Route path="/services2" element={<Services2 />} />
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Feature />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/services" element={<Services />} />

          <Route path="/payment" element={<Payment />} />
          <Route path="/case-detail/:id" element={<CaseDetail />} />
          <Route path="/not-found" element={<PageNotFound />} />
          <Route path="/internal-error" element={<InternalServerPage />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </BrowserRouter>
    );
  }

  //
  //
}
