import Sidebar from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Services.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
function Services() {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div>
      <Sidebar onClick={handleClick} />
      <div
        className={`container ${sidebarOpen ? "sidebar-open" : ""}`}
        style={{
          marginLeft: sidebarOpen ? "0" : "150px",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <div className="container5">
          <Link style={{ textDecoration: "none" }} to={"/payment"}>
            <button
              className="upgrade-button"
              style={{ backgroundColorcolor: "gold" }}
            >
              Upgrade Premuim <FontAwesomeIcon icon={faCrown} />
            </button>
          </Link>
          <h2 className="title5">Services</h2>

          <div className="container-fluid px-4">
            <div className="row g-3 my-2">
              <div className="col-12">
                {" "}
                {/* Utilisez la classe col-12 pour occuper toute la largeur */}
                <div className="row g-3">
                  {/* Carte 1 */}

                  <div className="col-md-3 card-container">
                    <Link style={{ textDecoration: "none" }} to={"/settings"}>
                      <div className="p-3 bg-white shadow-sm d-flex flex-column align-items-start rounded card">
                        {/* Photo qui prend la largeur de la carte et une hauteur fixe */}
                        <img
                          src="https://researchmethod.net/wp-content/uploads/2023/05/Documentary_Analysis-1024x576.jpg"
                          alt="Votre Photo"
                          className="img-fluid rounded"
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "150px",
                          }}
                        />

                        <h4
                          style={{
                            color: "black",
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          Document Analysis & Drafting
                        </h4>

                        <p style={{ color: "black", marginBottom: "10px" }}>
                          Access to a comprehensive legal database for in-depth
                          research and analysis.
                        </p>
                      </div>
                    </Link>
                  </div>

                  {/* Carte 2 */}
                  <div className="col-md-3 card-container">
                    <div className="p-3 bg-white shadow-sm d-flex flex-column align-items-start rounded card">
                      {/* Photo qui prend la largeur de la carte et une hauteur fixe */}
                      <img
                        src="https://miro.medium.com/v2/resize:fit:2000/1*tTeDBofT8MghSKPjDVA_gQ.jpeg"
                        alt="Icone 1"
                        style={{ width: "100%", height: "auto" }} // Ajustez la taille de l'image selon vos besoins
                      />

                      <h4
                        style={{
                          color: "black",
                          marginTop: "10px",
                          marginBottom: "130px",
                        }}
                      >
                        {" "}
                        Coming Soon
                      </h4>
                    </div>
                  </div>
                  {/* Carte 3 */}
                  <div className="col-md-3 card-container">
                    <div className="p-3 bg-white shadow-sm d-flex flex-column align-items-start rounded card">
                      <img
                        src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/308162306/original/cfb292556aaf61d7b7f8bac02dcc17e02b297c5f/do-legal-research-and-draft-legal-documents.png"
                        alt="Icone 1"
                        style={{ width: "100%", height: "auto" }} // Ajustez la taille de l'image selon vos besoins
                      />
                      <h4
                        style={{
                          color: "black",
                          marginTop: "10px",
                          marginBottom: "130px",
                        }}
                      >
                        {" "}
                        Coming Soon
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
