// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    margin-top: 20px;

    margin-right: auto;
    width: 80%; /* Vous pouvez ajuster la largeur selon vos besoins */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  button {
    margin-right: 5px;
  }
  .table-title {
    text-align: center;
    margin-bottom: 20px;
  }
  .paginationH {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .paginationH button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .paginationH button:hover {
    background-color: #0056b3;
  }
  
  .paginationH button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .paginationH .arrow {
    font-size: 18px;
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/Historique/Historique.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;;IAEhB,kBAAkB;IAClB,UAAU,EAAE,qDAAqD;EACnE;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB;EACA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,aAAa;IACb,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".table-container {\n    margin-top: 20px;\n\n    margin-right: auto;\n    width: 80%; /* Vous pouvez ajuster la largeur selon vos besoins */\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  th, td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  th {\n    background-color: #f2f2f2;\n  }\n  \n  button {\n    margin-right: 5px;\n  }\n  .table-title {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  .paginationH {\n    margin-top: 20px;\n    display: flex;\n    justify-content: center;\n  }\n  \n  .paginationH button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 10px;\n    margin: 0 5px;\n    cursor: pointer;\n    border-radius: 5px;\n  }\n  \n  .paginationH button:hover {\n    background-color: #0056b3;\n  }\n  \n  .paginationH button:disabled {\n    background-color: #6c757d;\n    cursor: not-allowed;\n  }\n  \n  .paginationH .arrow {\n    font-size: 18px;\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
