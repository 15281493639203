import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Historique.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { faEye, faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";

function Historique() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Nombre d'éléments par page

  // Supposons que votre historique soit stocké dans un tableau d'objets avec les propriétés nom, description et date
  const historiqueData = [
    {
      id: 1,
      nom: "Événement 1",
      description: "Description de l'événement 1",
      date: "01/01/2024",
    },
    {
      id: 2,
      nom: "Événement 2",
      description: "Description de l'événement 2",
      date: "02/01/2024",
    },
    // Ajoutez d'autres éléments d'historique selon vos besoins
  ];

  // Index de départ et index de fin pour les éléments de la page actuelle
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = historiqueData.slice(indexOfFirstItem, indexOfLastItem);

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Générer les numéros de page
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(historiqueData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [isMenuToggled, setIsMenuToggled] = useState(false);

  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuToggled(!isMenuToggled);
  };

  // Utilisez useEffect pour attacher l'événement de basculement lors du chargement du composant
  useEffect(() => {
    const toggleButton = document.getElementById("menu-toggle");
    toggleButton.addEventListener("click", toggleMenu);

    // Nettoyez l'écouteur d'événement lors du démontage du composant
    return () => {
      toggleButton.removeEventListener("click", toggleMenu);
    };
  }, []); // Le tableau vide signifie que cela ne doit s'exécuter qu'une fois après le montage

  // Ajoutez votre logique pour basculer la classe "toggled" ici
  useEffect(() => {
    const el = document.getElementById("wrapper");
    const toggleButton = document.getElementById("menu-toggle");

    const toggleWrapperClass = () => {
      el.classList.toggle("toggled");
    };

    toggleButton.addEventListener("click", toggleWrapperClass);

    return () => {
      toggleButton.removeEventListener("click", toggleWrapperClass);
    };
  }, []);
  const [profileData, setProfileData] = useState(null);
  const [ProfileDataToEdit, setProfileDataToEdit] = useState(null);
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    // Fonction pour récupérer les données du profil par ID
    const fetchProfileData = async () => {
      try {
        // Remplacez l'URL par l'URL de votre API GET par ID
        const response = await fetch(
          `https://bridgetojustice.ai:8443/profile/${user_id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch profile data");
        }
        const data = await response.json();
        setProfileData(data.user);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    // Appelez la fonction fetchProfileData une fois lorsque le composant est monté
    fetchProfileData();
  }, []);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    window.location.href = "/login";
  };
  const [historiques, setHistoriques] = useState([]);

  useEffect(() => {
    const fetchHistoriqueByUserId = async () => {
      try {
        const userId = localStorage.getItem("user_id"); // Remplacez par l'ID de l'utilisateur souhaité
        const response = await fetch(
          `https://bridgetojustice.ai:8443/historique/${userId}`
        );
        const data = await response.json();
        setHistoriques(data.historiques);
      } catch (error) {
        console.error("Error fetching historique:", error);
      }
    };

    fetchHistoriqueByUserId();
  }, []);
  return (
    <div className="d-flex" id="wrapper">
      <div className="bg-white" id="sidebar-wrapper">
        <div className="sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase border-bottom">
          <Link
            to="/"
            className="navbar-logo1"
            style={{
              color: "black",
              fontSize: "1.2rem",
              justifyContent: "center",
            }}
          >
            Legal Document
          </Link>
        </div>
        <div className="list-group list-group-flush my-3">
          <a
            href="/profile"
            className="list-group-item list-group-item-action bg-transparent second-text active"
            style={{ color: "black" }}
          >
            <i className="fas fa-user me-2"></i>Profil
          </a>

          <a
            href="/services2"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-tools me-2"></i>Services
          </a>

          <a
            href="/forum"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Forum
          </a>
          <a
            href="/historique"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Historique
          </a>
        </div>
        <div className="list-group mt-5">
          <a
            onClick={logout}
            className="list-group-item list-group-item-action bg-transparent text-danger fw-bold"
          >
            <i className="fas fa-power-off me-2"></i>Logout
          </a>
        </div>
      </div>

      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4">
          <div className="d-flex align-items-center">
            <i
              className="fas fa-align-left primary-text fs-4 me-3"
              id="menu-toggle"
              style={{ color: "black" }}
            ></i>
            <h2 className="fs-2 m-0">Historique</h2>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            color="black"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "black" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                {/* Utilisez la classe "d-flex" pour aligner le texte et l'avatar horizontalement */}
                <a
                  className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Remplacez "chemin_vers_votre_avatar" par le chemin de votre image d'avatar */}
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnfAxGV-fZxGL9elM_hQ2tp7skLeSwMyUiwo4lMm1zyA&s"
                    alt="Avatar"
                    className="me-2 rounded-circle"
                    style={{ width: "40px", height: "35px" }}
                  />
                  {profileData ? (
                    <p style={{ color: "black" }}> {profileData.username}</p>
                  ) : (
                    <p style={{ color: "black" }}>Loading...</p>
                  )}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Settings
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        <div className="container-fluid px-4">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>text</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {historiques.map((historique) => (
                  <tr key={historique.id}>
                    <td>{historique.text}</td>

                    <td>
                      {/* Utilisation des icônes de Font Awesome */}
                      <a href="/document">
                        <FontAwesomeIcon icon={faEye} />{" "}
                      </a>
                      {/* Icône de l'œil pour la visualisation */}
                      <FontAwesomeIcon icon={faTrash} />{" "}
                      {/* Icône de la corbeille pour la suppression */}
                      <FontAwesomeIcon icon={faDownload} />{" "}
                      {/* Icône de téléchargement */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="paginationH">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon className="arrow" icon={faArrowLeft} />
              </button>
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={currentPage === number ? "active" : ""}
                >
                  {number}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentItems.length < itemsPerPage}
              >
                <FontAwesomeIcon className="arrow" icon={faArrowRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Historique;
