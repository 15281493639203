import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Payment.css";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import credit from "../assets/images/19965318_6136985.jpg"
import dolar from '../assets/images/closeup-golden-usd-coins-dropping-dark-background-dollar-is-main-currency-exchange-payment-world-by-3d-render.jpg';
import { FaCcVisa, FaCcMastercard, FaCcAmex } from 'react-icons/fa';

function Payment() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [openPopup, setopenPopup] = useState(false);
  return (
    <div>
      <div style={{marginTop:'-13px'}}>
        <Sidebar onClick={handleClick} />
        <div
          className={`container ${sidebarOpen ? "sidebar-open" : ""}`}
          style={{
            marginLeft: sidebarOpen ? "0" : "150px",
            transition: "all 0.3s ease-in-out",
          }}
        >
      <Container className="mt-5">
        <Row>
          {/* Left Column */}
          <h2 className="text-center font-weight-bold mb-4">Payment Details</h2>
          <Col xs={12} md={6} style={{ marginTop: '50px' }}>

            <Image src={credit} alt="Credit Card" fluid />
          </Col>

          {/* Right Column */}
          <Col xs={12} md={6} style={{ marginTop: '50px' }}>
            <Card className="h-100" style={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)', borderRadius: '25px' }}>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col md={8} style={{ marginTop: '40px', marginBottom: '40px' }}>
                    {/* Form */}
                    <Form>
                      <FaCcVisa style={{ marginLeft: '10px', fontSize: '50px', color: '#1a1f71' }} />
                      <FaCcMastercard style={{ marginLeft: '10px', fontSize: '50px', color: '#eb001b' }} />
                      <FaCcAmex style={{ marginLeft: '10px', fontSize: '50px', color: '#007cbf' }} />

                      <Form.Group className="mb-3" controlId="formName">
                        <Form.Label style={{ color: 'white' }}>card holder name</Form.Label>
                        <Form.Control type="text" placeholder="Enter your full name" className="input-line" style={{ borderRadius: '10px' }} />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label style={{ color: 'white' }} >card number</Form.Label>
                        <Form.Control type="email" placeholder="1234 4567 8910 1112" className="input-line" style={{ borderRadius: '10px' }} />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label style={{ color: 'white' }}>cvv/cvc</Form.Label>
                        <Form.Control type="email" placeholder="000" className="input-line" style={{ borderRadius: '10px' }} />
                      </Form.Group>



                      <Button variant="primary" type="submit" style={{ marginTop: '30px', width: '100%', backgroundColor: 'black' }}>
                        Pay
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
      </div>
    </div>
  );
}

export default Payment;
