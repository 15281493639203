import "./Payment2.css";
import React, { useState } from "react";
import { FaRegGem, FaRegStar, FaRegHeart } from "react-icons/fa"; // Import des icônes de Font Awesome
function Payment2() {
  // Données des forfaits
  const packagesData = {
    silver: {
      title: "Silver Package",
      description: "5 process document / day ,5 downlaod file / day",
      price: "Free",
    },
    golden: {
      title: "Golden Package",
      description:
        "50 process document / day, 50 downlaod file / day , priority support",
      price: "$100",
    },
    premium: {
      title: "Premium Package",
      description:
        "illimit  process document / day ,illimit  downlaod file / day,Priority Support, api integration",
      price: "$200",
    },
  };
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
  };

  return (
    <div class="container d-lg-flex">
      <div class="box-1 bg-light user">
        {/* <div class="d-flex align-items-center mb-3">
          <img
            src="https://images.pexels.com/photos/4925916/pexels-photo-4925916.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            class="pic rounded-circle"
            alt=""
          />
          <p class="ps-2 name">Oliur</p>{" "}
  </div>*/}
        <div class="box-inner-1 pb-3 mb-3 ">
          <div class="d-flex justify-content-between mb-3 userdetails"></div>
          <div
            id="my"
            class="carousel slide carousel-fade img-details"
            data-bs-ride="carousel"
            data-bs-interval="2000"
          >
            <div class="carousel-indicators">
              {" "}
              <button
                type="button"
                data-bs-target="#my"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#my"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#my"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>{" "}
            </div>
            <div class="carousel-inner">
              {" "}
              <div class="carousel-item active">
                {" "}
                <img
                  src="https://scitechdaily.com/images/Legal-Document-Concept.jpg"
                  class="d-block w-100"
                />
              </div>{" "}
              <div class="carousel-item">
                {" "}
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpcWUKUbPIfChPyOi7yWs7aaAbpw26_ZmDV_xBYTp2-fCbfsoyXf1pbqyhiFEqo35fvIo&usqp=CAU"
                  class="d-block w-100"
                />
              </div>{" "}
              <div class="carousel-item">
                {" "}
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjWE3TXk1iJKkDjbfGIf2PFzWUp5gcGGqyGQmxYhsNocRXsai-m3SnlVInOcVCTotH7r4&usqp=CAU"
                  class="d-block w-100"
                />{" "}
              </div>{" "}
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#my"
              data-bs-slide="prev"
            >
              {" "}
              <div class="icon">
                {" "}
                <span class="fas fa-arrow-left"></span>{" "}
              </div>{" "}
              <span class="visually-hidden">Previous</span>{" "}
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#my"
              data-bs-slide="next"
            >
              {" "}
              <div class="icon">
                {" "}
                <span class="fas fa-arrow-right"></span>{" "}
              </div>{" "}
              <span class="visually-hidden">Next</span>{" "}
            </button>{" "}
          </div>{" "}
          <p class="dis my-3 info"></p>{" "}
          {/*<p class="dis mb-3 updates">Free updates forever</p>{" "}*/}
          <p
            class="dis mb-3 different"
            style={{ fontWeight: "bold", fontSize: "1rem" }}
          >
            Our packages:
          </p>{" "}
          <div>
            <div className="dis">
              <label>
                <input
                  type="radio"
                  name="package"
                  value="silver"
                  checked={selectedPackage === "silver"}
                  onChange={handlePackageChange}
                />
                Silver package
              </label>
              <label>
                <input
                  type="radio"
                  name="package"
                  value="golden"
                  checked={selectedPackage === "golden"}
                  onChange={handlePackageChange}
                />
                Golden package
              </label>
              <label>
                <input
                  type="radio"
                  name="package"
                  value="premium"
                  checked={selectedPackage === "premium"}
                  onChange={handlePackageChange}
                />
                Premium package
              </label>
            </div>
            {selectedPackage && (
              <div className="card-p">
                <h3>
                  {selectedPackage === "silver" && (
                    <FaRegGem
                      style={{ fontSize: "24px", marginRight: "5px" }}
                    />
                  )}
                  {selectedPackage === "golden" && (
                    <FaRegStar
                      style={{ fontSize: "24px", marginRight: "5px" }}
                    />
                  )}
                  {selectedPackage === "premium" && (
                    <FaRegHeart
                      style={{ fontSize: "24px", marginRight: "5px" }}
                    />
                  )}
                  {packagesData[selectedPackage].title}
                </h3>

                <ul>
                  {packagesData[selectedPackage].description
                    .split(",")
                    .map((item, index) => (
                      <li style={{ color: "black" }} key={index}>
                        {item.trim()}
                      </li>
                    ))}
                </ul>
                <p>Price: {packagesData[selectedPackage].price}</p>
              </div>
            )}
          </div>
          {/* <div>
            {" "}
            <p class="dis footer my-3">
              Here is a quick guide on how to apply them
            </p>{" "}
  </div>{" "}*/}
        </div>{" "}
      </div>{" "}
      <div class="box-2">
        {" "}
        <div class="box-inner-2">
          {" "}
          <div>
            {" "}
            <p class="fw-bold">Payment Details</p>{" "}
            <p class="dis mb-3">
              Complete your purchase by providing your payment details
            </p>{" "}
          </div>
          <form action="">
            {" "}
            <div class="mb-3">
              {" "}
              <p class="dis fw-bold mb-2">Email address</p>{" "}
              <input
                class="form-control"
                type="email"
                value="luke@skywalker.com"
              />{" "}
            </div>{" "}
            <div>
              <p class="dis fw-bold mb-2">Card details</p>{" "}
              <div class="d-flex align-items-center justify-content-between card-atm border rounded">
                {" "}
                <div class="fab fa-cc-visa ps-3"></div>{" "}
                <input
                  type="text"
                  class="form-control"
                  placeholder="Card Details"
                />{" "}
                <div class="d-flex w-50">
                  {" "}
                  <input
                    type="text"
                    class="form-control px-0"
                    placeholder="MM/YY"
                  />{" "}
                  <input
                    type="password"
                    class="form-control px-0"
                    placeholder="CVV"
                  />{" "}
                </div>{" "}
              </div>{" "}
              <div class="my-3 cardname">
                {" "}
                <p class="dis fw-bold mb-2">Cardholder name</p>{" "}
                <input class="form-control" type="text" />{" "}
              </div>{" "}
              <div class="address">
                {" "}
                <p class="dis fw-bold mb-3">Billing address</p>{" "}
                <select class="form-select" aria-label="Default select example">
                  {" "}
                  <option selected hidden>
                    United States
                  </option>{" "}
                  <option value="1">India</option>{" "}
                  <option value="2">Australia</option>{" "}
                  <option value="3">Canada</option>{" "}
                </select>{" "}
                <div class="d-flex">
                  {" "}
                  <input
                    class="form-control zip"
                    type="text"
                    placeholder="ZIP"
                  />{" "}
                  <input
                    class="form-control state"
                    type="text"
                    placeholder="State"
                  />{" "}
                </div>{" "}
                <div class=" my-3">
                  {" "}
                  <p class="dis fw-bold mb-2">VAT Number</p>{" "}
                  <div class="inputWithcheck">
                    {" "}
                    <input
                      class="form-control"
                      type="text"
                      value="GB012345B9"
                    />{" "}
                    <span class="fas fa-check"></span>{" "}
                  </div>{" "}
                </div>{" "}
                <div class="my-3">
                  {" "}
                  <p class="dis fw-bold mb-2">Discount Code</p>{" "}
                  <input
                    class="form-control text-uppercase"
                    type="text"
                    value="BLACKFRIDAY"
                    id="discount"
                  />{" "}
                </div>{" "}
                <div class="d-flex flex-column dis">
                  {" "}
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    {" "}
                    <p>Subtotal</p>{" "}
                    <p>
                      <span class="fas fa-dollar-sign"></span>19.00
                    </p>{" "}
                  </div>{" "}
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    {" "}
                    <div class="d-flex align-items-center">
                      {" "}
                      <p class="pe-2">
                        Discount{" "}
                        <span class="d-inline-flex align-items-center justify-content-between bg-light px-2 couponCode">
                          {" "}
                          <span id="code" class="pe-2"></span>{" "}
                          <span class="fas fa-times close"></span>{" "}
                        </span>{" "}
                      </p>{" "}
                    </div>{" "}
                    <p>
                      <span class="fas fa-dollar-sign"></span>5.00
                    </p>{" "}
                  </div>{" "}
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    {" "}
                    <p>
                      VAT<span>(20%)</span>
                    </p>{" "}
                    <p>
                      <span class="fas fa-dollar-sign"></span>2.80
                    </p>{" "}
                  </div>{" "}
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    {" "}
                    <p class="fw-bold">Total</p>{" "}
                    <p class="fw-bold">
                      <span class="fas fa-dollar-sign"></span>16.80
                    </p>{" "}
                  </div>{" "}
                  <div class="btn btn-primary mt-2">
                    Pay<span class="fas fa-dollar-sign px-1"></span>16.80{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </form>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}

export default Payment2;
