import React, { useState } from "react";
import "./Sidebar.css";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, Link } from "react-router-dom";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sidebar = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const isActive = (path) => {
    return window.location.pathname === path;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          height: "100vh",
          position: "fixed",
          marginTop: "-35px",
          overflow: "scroll initial",
        }}
      >
        <CDBSidebar textColor="#fff" backgroundColor="black" show={showSidebar}>
          {/* Section pour l'utilisateur */}
          <CDBSidebarHeader
            prefix={<i className="fa fa-bars fa-large"></i>}
            onClick={props.onClick}
          >
            <Link to="/" className="navbar-logo1" style={{}}>
              Legal Document
            </Link>
            {/* Photo de l'utilisateur */}
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/6833/6833605.png"
                alt="Photo de profil"
                style={{ width: "80px", height: "80px", borderRadius: "50%" }}
              />
            </div>
            {/* Nom de l'utilisateur */}
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              Nom Utilisateur
            </div>
          </CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              {/* Vos éléments de menu vont ici */}

              <NavLink exact to="/services" activeClassName="activeClicked">
                <CDBSidebarMenuItem
                  icon="hotel"
                  style={isActive(`/services`) ? { color: "violet" } : {}}
                >
                  Services
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/profile"
                style={
                  isActive("/profile")
                    ? { backgroundColor: "black", color: "violet" }
                    : {}
                }
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="user">Profile </CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/historique" activeClassName="activeClicked">
                <CDBSidebarMenuItem
                  icon="columns"
                  style={isActive(`/historique`) ? { color: "violet" } : {}}
                >
                  Historique
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/forum" activeClassName="activeClicked">
                <CDBSidebarMenuItem
                 icon="comment"
                  style={isActive(`/forum`) ? { color: "violet" } : {}}
                >
                  Forum
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="power-off">Logout</CDBSidebarMenuItem>
              </NavLink>

              {/* Ajoutez d'autres liens ici */}
            </CDBSidebarMenu>
          </CDBSidebarContent>

          <CDBSidebarFooter style={{ textAlign: "center" }}></CDBSidebarFooter>
        </CDBSidebar>
      </div>
    </div>
  );
};

export default Sidebar;
