import React from "react";
import "./Footer.css";
//import { Button } from "../../Components/Button";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import LegalDoc from "../assets/images/LegalDoc (1).png";
function Footer() {
  return (
    <div className="footer-container" style={{ marginTop: "" }}>
      <div className="footer-links">
        <div className="footer-link-wrapper" style={{}}>
          <div className="footer-link-items" style={{}}>
            <h2>Contact us</h2>

            <Link to="#contact">Contact</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items" style={{ marginLeft: "60px" }}>
            <h2>social networks</h2>
            <Link to="/">Instagram</Link>
            <Link to="https://www.facebook.com/ESPRIMS">Facebook</Link>
            <Link to="/">Youtube</Link>
            <Link to="/">Twitter</Link>
          </div>
        </div>
        <div>
          <img src={LegalDoc} alt="aya" style={{ height: "300px" }} />
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              legal document
            </Link>
          </div>
          <small className="website-rights">legal document © 2024</small>
          <div className="social-icons">
            <Link
              className="social-icon-link facebook"
              to="/"
              target="_blank"
              aria-label="Facebook"
              style={{ color: "white" }}
            >
              <i className="fab fa-facebook-f" />
            </Link>
            <Link
              className="social-icon-link instagram"
              to="/"
              target="_blank"
              aria-label="Instagram"
              style={{ color: "white" }}
            >
              <i className="fab fa-instagram" />
            </Link>
            <Link
              className="social-icon-link youtube"
              to="/"
              target="_blank"
              aria-label="Youtube"
              style={{ color: "white" }}
            >
              <i className="fab fa-youtube" />
            </Link>
            <Link
              className="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="Twitter"
              style={{ color: "white" }}
            >
              <i className="fab fa-twitter" />
            </Link>
            <Link
              className="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="LinkedIn"
              style={{ color: "white" }}
            >
              <i className="fab fa-linkedin" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
