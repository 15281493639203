import React from "react";
import {
  FaDatabase,
  FaFileAlt,
  FaPenAlt,
  FaQuestionCircle,
  FaUnlockAlt,
  FaShieldAlt,
} from "react-icons/fa";
import "./Feature.css";

function Feature() {
  return (
    <div className="container1">
      <h2 className="titleF">Features</h2>
      <div className="cardsContainer">
        <div className="card1">
          <FaDatabase className="icon5" />
          <h4 style={{ color: "#0B2139" }}>comprehensive knowledge base</h4>
          <p>
            Access to a vast repository of case law and statues with advanced
            search capabilities
          </p>
        </div>
        <div className="card1">
          <FaFileAlt className="icon5" />
          <h4 style={{ color: "#0B2139" }}>Document management system</h4>
          <p>
            Ensure easy access to your documents along with a comprehensive
            history of processes
          </p>
        </div>
        <div className="card1">
          <FaPenAlt className="icon5" />
          <h4 style={{ color: "#0B2139" }}>Document Analysis & Drafting</h4>
          <p>
            Hrness the power of natural language Processing for flawless
            demurrers.
          </p>
        </div>
      </div>
      <div className="cardsContainer">
        <div className="card1">
          <FaQuestionCircle className="icon5" />
          <h4 style={{ color: "#0B2139" }}>24/7 support & assistance</h4>
          <p>
            Reach out for technical help or browse through our extensive FAQ.
          </p>
        </div>
        <div className="card1">
          <FaUnlockAlt className="icon5" />
          <h4 style={{ color: "#0B2139" }}>Flexible subscription Options</h4>
          <p>choose from free access or unlock full features with premuim.</p>
        </div>
        <div className="card1">
          <FaShieldAlt className="icon5" />
          <h4 style={{ color: "#0B2139" }}>Secure Account Creation</h4>
          <p>Stay protected with state-of-the-art security measures.</p>
        </div>
      </div>
    </div>
  );
}

export default Feature;
