import React, { useState, useEffect } from "react";
import "./Profil.css";

import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import EditProfile from "./EditProfil";
import Popup from "../Popup/Popup";
import image from "../assets/images/gettyimages-1334476315-612x612.jpg";

function Profile() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [ProfileDataToEdit, setProfileDataToEdit] = useState(null);
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    // Fonction pour récupérer les données du profil par ID
    const fetchProfileData = async () => {
      try {
        // Remplacez l'URL par l'URL de votre API GET par ID
        const response = await fetch(
          `https://bridgetojustice.ai:8443/profile/${user_id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch profile data");
        }
        const data = await response.json();
        setProfileData(data.user);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    // Appelez la fonction fetchProfileData une fois lorsque le composant est monté
    fetchProfileData();
  }, []);
  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuToggled(!isMenuToggled);
  };

  // Utilisez useEffect pour attacher l'événement de basculement lors du chargement du composant
  useEffect(() => {
    const toggleButton = document.getElementById("menu-toggle");
    toggleButton.addEventListener("click", toggleMenu);

    // Nettoyez l'écouteur d'événement lors du démontage du composant
    return () => {
      toggleButton.removeEventListener("click", toggleMenu);
    };
  }, []); // Le tableau vide signifie que cela ne doit s'exécuter qu'une fois après le montage

  // Ajoutez votre logique pour basculer la classe "toggled" ici
  useEffect(() => {
    const el = document.getElementById("wrapper");
    const toggleButton = document.getElementById("menu-toggle");

    const toggleWrapperClass = () => {
      el.classList.toggle("toggled");
    };

    toggleButton.addEventListener("click", toggleWrapperClass);

    return () => {
      toggleButton.removeEventListener("click", toggleWrapperClass);
    };
  }, []);
  const navigate = useNavigate();
  const handleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [openPopup, setopenPopup] = useState(false);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    window.location.href = "/login";
  };
  return (
    <div className="d-flex" id="wrapper">
      <div className="bg-white" id="sidebar-wrapper">
        <div className="sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase border-bottom">
          <Link
            to="/"
            className="navbar-logo1"
            style={{
              color: "black",
              fontSize: "1.2rem",
              justifyContent: "center",
            }}
          >
            Legal Document
          </Link>
        </div>
        <div className="list-group list-group-flush my-3">
          <a
            href="/profile"
            className="list-group-item list-group-item-action bg-transparent second-text active"
            style={{ color: "black" }}
          >
            <i className="fas fa-user me-2"></i>Profil
          </a>

          <a
            href="/services2"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-tools me-2"></i>Services
          </a>

          <a
            href="/forum"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Forum
          </a>
          <a
            href="/historique"
            className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
            style={{ color: "black" }}
          >
            <i className="fas fa-info-circle me-2"></i>Historique
          </a>
        </div>
        <div className="list-group mt-5">
          <a
            onClick={logout}
            className="list-group-item list-group-item-action bg-transparent text-danger fw-bold"
          >
            <i className="fas fa-power-off me-2"></i>Logout
          </a>
        </div>
      </div>

      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4">
          <div className="d-flex align-items-center">
            <i
              className="fas fa-align-left primary-text fs-4 me-3"
              id="menu-toggle"
              style={{ color: "black" }}
            ></i>
            <h2 className="fs-2 m-0">Profile</h2>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            color="black"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "black" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                {/* Utilisez la classe "d-flex" pour aligner le texte et l'avatar horizontalement */}
                <a
                  className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Remplacez "chemin_vers_votre_avatar" par le chemin de votre image d'avatar */}
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnfAxGV-fZxGL9elM_hQ2tp7skLeSwMyUiwo4lMm1zyA&s"
                    alt="Avatar"
                    className="me-2 rounded-circle"
                    style={{ width: "40px", height: "35px" }}
                  />
                  {profileData ? (
                    <p style={{ color: "black" }}> {profileData.username}</p>
                  ) : (
                    <p style={{ color: "black" }}>Loading...</p>
                  )}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/profile">
                      Profile
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" onClick={logout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>

        <div>
          <div className="container-fluid px-4">
            <div className="content1">
              <div className="profile">
                <div className="profile-header">
                  <img
                    src="https://alisoumare.fr/wp-content/uploads/2019/02/b028e7176b71f09034d029843a54c36416ccdb84_shutterstock_1029430792-compressor.jpg"
                    alt={`Background`}
                    className="profile-picture"
                  />
                  {/* Circular profile picture */}
                  <img
                    src={image}
                    alt={`Profile`}
                    className="circular-profile-picture"
                  />
                </div>
                <div className="profile-info">
                  <label style={{ fontFamily: "cursive", color: "grey" }}>
                    Name{" "}
                  </label>
                  {profileData ? (
                    <p style={{ color: "black" }}> {profileData.username}</p>
                  ) : (
                    <p style={{ color: "black" }}>Loading...</p>
                  )}
                  <label style={{ fontFamily: "cursive", color: "grey" }}>
                    Email{" "}
                  </label>
                  {profileData ? (
                    <p style={{ color: "black" }}>{profileData.email}</p>
                  ) : (
                    <p style={{ color: "black" }}> Loading...</p>
                  )}
                  <button
                    className="btn1 btn-primary btn-edit"
                    onClick={() => {
                      setopenPopup(true);
                      // Passer les données du profil à EditProfile
                      setProfileDataToEdit(profileData); // Mettez à jour l'état avec les données du profil
                    }}
                  >
                    Edit
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
          <Popup openPopup={openPopup} setopenPopup={setopenPopup}>
            <EditProfile
              profileData={profileData}
              setProfileDataToEdit={setProfileDataToEdit}
              setOpenPopup={setopenPopup}
            />
          </Popup>
        </div>
      </div>
    </div>
  );
}

export default Profile;
