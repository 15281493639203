import React, { useState, useMemo, useCallback } from "react";
import imageTop from "../assets/images/SFG-Lawyer.jpg";
import {
  Col,
  Container,
  Image,
  Pagination,
  ProgressBar,
  Row,
} from "react-bootstrap";

import { BsSearch } from "react-icons/bs";
import avatar from "../assets/images/téléchargement (1).jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp,
  faComment,
  faEye,
  faClock,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsJustify, BsChevronDown } from "react-icons/bs";
import "../topbar/ToolBar.css";
import { useEffect } from "react";
import toolbarAvatar from "../assets/images/téléchargement.jpeg";

import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Dropdown } from "react-bootstrap";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";

const Forum = () => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [search, setSearch] = useState("");
  const [commentCount, setCommentCount] = useState(16); // Initial comment count
  const [likeCount, setLikeCount] = useState(163);
  const [dislikeCount, setDislikeCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cases, setCases] = useState([]);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setimage] = useState("");

  const [updatedCases, setUpdatedCases] = useState(cases);
  const [commentText, setCommentText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [categoriesWithPercentage, setCategoriesWithPercentage] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [imageURL, setImageURL] = useState("");

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory); // Mettre à jour l'état de la catégorie lorsque l'utilisateur sélectionne une option
  };

  const handleDescriptionChange = (e) => {
    setdescription(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Récupérer le premier fichier sélectionné
    console.log("Selected file:", file); // Afficher les détails du fichier sélectionné

    if (file) {
      setimage(file); // Mettre à jour la valeur de l'image dans l'état
      const imageURL = URL.createObjectURL(file); // Créer une URL à partir du fichier sélectionné
      setImageURL(imageURL); // Mettre à jour l'URL de l'image dans l'état
    }
  };

  useEffect(() => {
    const fetchDistinctCategoriesWithPercentage = async () => {
      try {
        const response = await fetch(
          "https://bridgetojustice.ai:8443/categories-pourcentage"
        );
        if (response.ok) {
          const data = await response.json();
          setCategoriesWithPercentage(data);
        } else {
          console.error(
            "Error fetching distinct categories with percentage:",
            response.statusText
          );
        }
      } catch (error) {
        console.error(
          "Error fetching distinct categories with percentage:",
          error
        );
      }
    };

    fetchDistinctCategoriesWithPercentage();
  }, []);

  const itemsPerPage = 5;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://bridgetojustice.ai:8443/cases");
      if (response.ok) {
        const data = await response.json();
        // Réorganiser les données en ordre décroissant basé sur une propriété "date" (ou toute autre propriété temporelle appropriée)
        const sortedData = [...data].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCases(sortedData);
      } else {
        console.error("Error fetching cases:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching cases:", error);
    }
  };
  // Pagination logic
  const indexOfLastCase = currentPage * itemsPerPage;
  const indexOfFirstCase = indexOfLastCase - itemsPerPage;
  const currentCases = cases.slice(indexOfFirstCase, indexOfLastCase);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleSubmit = async (e) => {
    //e.preventDefault();
    // Vérifier si les champs requis sont vides
    if (!title || !category || !description) {
      toast.error("Please fill in all required fields");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("category", category);
      formData.append("description", description);
      formData.append("image", image); // Ensure this is the file object
      formData.append("user_id", localStorage.getItem("user_id"));

      const response = await fetch("https://bridgetojustice.ai:8443/case", {
        method: "POST",
        body: formData, // Send as FormData
      });

      if (response.ok) {
        toast.success("Post successful");
        console.log("Post successful");
        setTitle("");
        setCategory("");
        setdescription("");
        setimage(null);
        setImageURL("");
        // Rafraîchir les données après la publication
        fetchData();
      } else {
        const errorText = await response.text();
        console.error("Post failed:", errorText);
        toast.error("Post failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error occurred while posting");
    }
  };
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const [showMenu, setShowMenu] = useState(false);
  const handleToggleComponent = () => {
    setShowComponent(!showComponent);
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const navigate = useNavigate();

  const handleClickV = () => {
    // Redirige vers la page souhaitée lors du clic sur l'icône
    navigate("/services2");
  };

  const [showCommentInputs, setShowCommentInputs] = useState({});

  const handleCommentClick = (id) => {
    // Mettre à jour l'état pour afficher le champ de commentaire pour la carte spécifique
    setShowCommentInputs((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoriesWithCount, setCategoriesWithCount] = useState([]);

  useEffect(() => {
    const fetchCategoriesWithCount = async () => {
      try {
        const response = await axios.get("https://bridgetojustice.ai:8443/categories");
        setCategoriesWithCount(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchCategoriesWithCount();
  }, []);
  // Fonction pour récupérer les cas par catégorie
  const fetchCasesByCategories = async (categories) => {
    try {
      let response;
      if (categories.length === 0) {
        // Si aucune catégorie n'est sélectionnée, récupérer les données pour toutes les catégories
        response = await axios.get(`https://bridgetojustice.ai:8443/cases`);
      } else {
        // Sinon, récupérer les données pour les catégories sélectionnées
        response = await Promise.all(
          categories.map((category) =>
            axios.get(`https://bridgetojustice.ai:8443/cases/${category}`)
          )
        );
      }

      const data = Array.isArray(response)
        ? response.map((res) => res.data).flat()
        : response.data;
      setCases(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCasesByCategories(selectedCategories);
  }, [selectedCategories]); // Exécuter l'effet à chaque changement des catégories sélectionnées

  const handleCheckboxClick = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  // Si le chargement est en cours, affichez un indicateur de chargement
  if (loading) {
    return <div>Loading...</div>;
  }
  const handleLike1 = async (caseId) => {
    try {
      const response = await axios.post(
        `https://bridgetojustice.ai:8443/cases/like/${caseId}`
      );
      // Mettre à jour l'état ou effectuer d'autres actions en fonction de la réponse
    } catch (error) {
      console.error("Error liking case:", error);
      // Gérer les erreurs
    }
  };

  const handleDislike1 = async (caseId) => {
    try {
      const response = await axios.post(
        `https://bridgetojustice.ai:8443/cases/dislike/${caseId}`
      );
      // Mettre à jour l'état ou effectuer d'autres actions en fonction de la réponse
    } catch (error) {
      console.error("Error disliking case:", error);
      // Gérer les erreurs
    }
  };

  const handleClickview = async (id) => {
    try {
      const response = await fetch(`https://bridgetojustice.ai:8443/cases/view/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const updatedCase = await response.json();
        const updatedCasesArray = updatedCases.map((cas) => {
          if (cas._id === updatedCase._id) {
            return { ...cas, views: updatedCase.views };
          }
          return cas;
        });
        setUpdatedCases(updatedCasesArray);
      } else {
        throw new Error("Failed to increment views");
      }
    } catch (error) {
      console.error("Error incrementing views:", error);
    }
  };

  const handleSubmitComment = async (caseId) => {
    const userId = localStorage.getItem("user_id");
    //e.preventDefault();
    try {
      const response = await fetch(
        `https://bridgetojustice.ai:8443/cases/comments/${caseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId, text: commentText }),
        }
      );
      if (response.ok) {
        // Le commentaire a été ajouté avec succès
        setCommentText("");
        setError(null);
        // Vous pouvez également mettre à jour l'état de votre application pour refléter le nouveau commentaire si nécessaire
      } else {
        throw new Error("Failed to post comment");
      }
    } catch (error) {
      console.error("Error posting comment:", error);
      setError("An error occurred while posting the comment");
    }
  };

  const handleSearchInput = (event) => {
    const searchValue = event.target.value.trim();

    setSearch(searchValue);

    if (searchValue === "") {
      // Si le champ de recherche est vide, exécuter la requête pour récupérer tous les cas
      axios
        .get(`https://bridgetojustice.ai:8443/cases`)
        .then((response) => {
          setCases(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // Si le champ de recherche contient du texte, exécuter la requête de recherche
      axios
        .get(`https://bridgetojustice.ai:8443/casessearch/${searchValue}`)
        .then((response) => {
          setCases(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Gérer le clic sur le bouton de filtre
  const handleFilterButtonClick = () => {
    // Inverser l'état de l'affichage de la liste déroulante
    setShowOptions(!showOptions);
  };

  // Gérer la sélection d'une option dans la liste déroulante
  const handleOptionSelect = async (value) => {
    console.log("Option sélectionnée :", value);
    setShowOptions(false); // Cacher la liste déroulante après la sélection

    try {
      const response = await axios.get(
        `https://bridgetojustice.ai:8443/cases-filter?filterBy=${value}`
      );
      const filteredCases = response.data;
      console.log("Cas filtrés :", filteredCases);
      setCases(filteredCases); // Mettre à jour l'état avec les cas filtrés
    } catch (error) {
      console.error("Erreur lors de la récupération des cas filtrés :", error);
    }
  };

  return (
    <div>
      <div
        className="d-flex align-items-start"
        style={{
          minHeight: "40vh", // Adjust the height as needed
          backgroundImage: `url(${imageTop})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      ></div>
      <header className="header">
        <div className="menu-icon">
          <BsJustify className="icon" onClick={OpenSidebar} />
        </div>
        <div className="header-left" onClick={handleClickV}>
          <FontAwesomeIcon icon={faArrowLeft} /> Go Back
        </div>
        <div className="header-right" style={{ position: "relative" }}>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={toggleMenu}
          >
            <div class="search-container">
              <div class="input-container">
                <input
                  type="text"
                  id="search-bar"
                  placeholder="Rechercher..."
                  name="search"
                  value={search}
                  onChange={handleSearchInput}
                />
              </div>
              <div className="dropdown">
                <button
                  id="filter-button"
                  className="dropdown-toggle"
                  onClick={handleFilterButtonClick}
                >
                  Filtrer
                </button>
                <div
                  id="filter-options"
                  className={`dropdown-menu ${showOptions ? "show" : ""}`}
                >
                  <a
                    href="#"
                    className="dropdown-item"
                    data-value="newest"
                    onClick={() => handleOptionSelect("newest")}
                  >
                    Newest
                  </a>
                  <a
                    href="#"
                    className="dropdown-item"
                    data-value="mostlikes"
                    onClick={() => handleOptionSelect("mostlikes")}
                  >
                    Most Likes
                  </a>
                </div>
              </div>
            </div>
            <BsSearch className="icon bg-transparent" />
            <Button
              style={{ backgroundColor: "black", height: "100%" }}
              onClick={handleToggleComponent}
              className="button-header"
            >
              New Topic
            </Button>

            <Image
              src={avatar}
              alt="Avatar"
              roundedCircle
              style={{ width: "30px", height: "30px", marginLeft: "10px" }}
            />
            <BsChevronDown style={{ marginLeft: "10px", marginTop: "-7px" }} />
          </div>
        </div>
      </header>
      <ToastContainer />
      <div style={{ backgroundColor: "#eeeee4" }}>
        <Container fluid>
          <Row className="mx-5">
            {" "}
            {/* Marge à gauche et à droite */}
            <Col xs={12} lg={6}>
              {/* Conteneurs à gauche */}
              {showComponent && (
                <Container
                  style={{
                    backgroundColor: "white",
                    height: "auto",
                    marginBottom: "10px",
                    padding: "5%",
                  }}
                >
                  <Row className="align-items-start">
                    <Col xs={12} sm={3}>
                      <Image
                        src={toolbarAvatar}
                        roundedCircle
                        alt="Avatar"
                        fluid
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Col>
                    <Col xs={12} sm={9}>
                      <Form>
                        <Form.Group controlId="title" className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Enter topic title"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <Dropdown className="mb-3">
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            className="w-100"
                            required
                            style={{
                              textAlign: "left",
                              backgroundColor: "white",
                              color: "#676565",
                              border: "1px solid rgba(0,0,0,0.2)",
                            }}
                          >
                            {category ? category : "Select Category"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="w-100"
                            style={{ textAlign: "left" }}
                          >
                            <Dropdown.Item
                              onClick={() => handleCategoryChange("Family Law")}
                            >
                              Family Law
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                handleCategoryChange("Criminal Law")
                              }
                              value={category}
                            >
                              Criminal Law
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                handleCategoryChange("Personal Injury Law")
                              }
                            >
                              Personal Injury Law
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                handleCategoryChange("Business Law")
                              }
                            >
                              Business Law
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        <Form.Group
                          controlId="exampleForm.ControlTextarea1"
                          className="mb-3"
                        >
                          <div style={{ display: "flex" }}>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Description (vous pouvez écrire du texte ou télécharger une image)"
                              value={description} // Utilisez la propriété 'text' de l'objet 'description'
                              onChange={handleDescriptionChange}
                              required
                            />
                            {image && ( // Vérifiez si la propriété 'image' de l'objet 'description' est définie
                              <div className="mb-3">
                                <img
                                  src={imageURL} // Utilisez createObjectURL pour afficher l'image à partir de l'objet de fichier
                                  alt="Uploaded"
                                  style={{ maxWidth: "100px" }}
                                />
                              </div>
                            )}
                          </div>
                          <label
                            htmlFor="fileInput"
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon icon={faCamera} size="lg" />
                            <input
                              id="fileInput"
                              name="image"
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                          </label>
                        </Form.Group>

                        <Button
                          variant="primary"
                          type="submit"
                          className="float-right"
                          onClick={() => {
                            handleToggleComponent();
                            handleSubmit();
                          }}
                        >
                          Post
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              )}
              {cases.length === 0 ? (
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  There are no elements.
                </p>
              ) : (
                currentCases.map((cas) => (
                  /*<Link
                  //to={`/case_detail/${cas._id}`}
                  style={{ textDecoration: "none" }}
                  //onClick={() => handleClickview(cas._id)}
              >*/
                  <Container
                    key={cas._id}
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      marginBottom: "10px",
                      borderRadius: "12px",
                    }}
                  >
                    <Row className="align-items-center ">
                      <Col xs={12} md={2}>
                        <Image
                          src={avatar}
                          roundedCircle
                          alt="Avatar"
                          fluid
                          style={{ width: "50px", height: "50px" }}
                        />
                      </Col>{" "}
                      <Col xs={12} md={10} style={{ paddingTop: "2%" }}>
                        <h4>{cas.title}</h4>

                        <div style={{ marginBottom: "10px" }}>
                          {cas.image ? (
                            <Image
                              src={`https://bridgetojustice.ai:8443/uploads/${cas.image}`}
                              alt="Description"
                              fluid
                              style={{ width: "100%" }}
                            />
                          ) : null}
                        </div>
                        <p style={{ color: "#504E4E" }}>
                          {cas.description}
                          <a
                            href={`https://bridgetojustice.ai/case-detail/${cas._id}`}
                            style={{ marginLeft: "10px" }}
                          >
                            Show Details
                          </a>
                        </p>
                        <div></div>
                        {/* Input de commentaire */}

                        <Col
                          className="dflex align-items-center"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "25px",
                            //marginBottom: "20px",
                          }}
                        >
                          {/* Icônes et compteurs */}
                          <span style={{ marginRight: "10px" }}>
                            <FontAwesomeIcon
                              icon={faEye}
                              style={{ color: "blue" }}
                            />{" "}
                            {cas.views} views
                          </span>
                          <span style={{ marginRight: "10px" }}>
                            <FontAwesomeIcon
                              icon={faThumbsUp}
                              onClick={() => handleLike1(cas._id)}
                              style={{ color: "green" }}
                            />{" "}
                            {cas.likes} likes
                          </span>
                          <span style={{ marginRight: "10px" }}>
                            <FontAwesomeIcon
                              icon={faThumbsDown}
                              onClick={() => handleDislike1(cas._id)}
                              style={{ color: "red" }}
                            />{" "}
                            {cas.dislikes} dislikes
                          </span>
                          <span style={{ marginRight: "10px" }}>
                            <FontAwesomeIcon
                              icon={faComment}
                              onClick={() => handleCommentClick(cas._id)}
                            />{" "}
                            {cas.commentsCount} comments
                          </span>
                        </Col>
                        {showCommentInputs[cas._id] && (
                          <div style={{ marginTop: "10px" }}>
                            <input
                              type="text"
                              placeholder="Your comment..."
                              value={commentText}
                              onChange={(e) => setCommentText(e.target.value)}
                              style={{
                                marginRight: "10px",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                flex: "1",
                              }}
                            />
                            <button
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "#4CAF50",
                                color: "white",
                                padding: "10px 20px",
                                textAlign: "center",
                                textDecoration: "none",
                                display: "inline-block",
                                fontSize: "16px",
                                borderRadius: "5px",
                                border: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => handleSubmitComment(cas._id)}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Container>
                ))
              )}
            </Col>
            <Col xs={12} lg={4} style={{ paddingTop: "1.7%" }}>
              {/* Conteneurs à droite */}
              {categoriesWithCount.length > 0 && (
                <div
                  style={{
                    backgroundColor: "white",
                    height: "40%",
                    marginBottom: "35px",
                    height: "auto",
                    borderRadius: "12px",
                  }}
                >
                  {/* Titre 1 */}
                  <p style={{ padding: "2%" }}>Categories</p>
                  {/* Ligne */}
                  <hr style={{ color: "GrayText" }} />
                  {/* Titre 2 */}
                  <div
                    style={{
                      paddingLeft: "2%",
                      paddingRight: "2%",
                    }}
                  >
                    {categoriesWithCount.map((category) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{category._id}</p>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "5px",
                              marginTop: "5px",
                            }}
                            onClick={() => handleCheckboxClick(category._id)}
                          />
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              backgroundColor: "gray",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {category.count}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {categoriesWithPercentage.length > 0 && (
                <div
                  style={{
                    backgroundColor: "white",
                    height: "auto",
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderRadius: "12px",
                  }}
                >
                  {/* Titre 1 */}
                  <p style={{ paddingLeft: "2%", paddingTop: "2%" }}>
                    Categories
                  </p>
                  {/* Ligne */}
                  <hr style={{ color: "GrayText" }} />
                  {/* Liste des catégories */}
                  <p style={{ paddingLeft: "2%" }}>
                    which case you are talking about this week ?
                  </p>
                  <div style={{ paddingLeft: "2%" }}>
                    {categoriesWithPercentage.map((category) => (
                      <div key={category._id} style={{ marginBottom: "10px" }}>
                        <ProgressBar
                          now={category.percentage}
                          label={
                            <div style={{ color: "white", fontWeight: "bold" }}>
                              {category._id}
                            </div>
                          }
                          variant={
                            category.percentage >= 80
                              ? "success"
                              : category.percentage >= 60
                              ? "warning"
                              : category.percentage >= 40
                              ? "info"
                              : "default"
                          }
                          style={{
                            width: "90%",
                            height: "30px",
                            marginBottom: "5px",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Col>
            {cases.length > 0 && (
              <Pagination style={{ display: "flex", justifyContent: "center" }}>
                <Pagination.First onClick={() => paginate(1)} />
                <Pagination.Prev
                  onClick={() =>
                    paginate(currentPage === 1 ? 1 : currentPage - 1)
                  }
                />
                {[...Array(Math.ceil(cases.length / itemsPerPage)).keys()].map(
                  (number) => (
                    <Pagination.Item
                      key={number + 1}
                      active={number + 1 === currentPage}
                      onClick={() => paginate(number + 1)}
                    >
                      {number + 1}
                    </Pagination.Item>
                  )
                )}
                <Pagination.Next
                  onClick={() =>
                    paginate(
                      currentPage === Math.ceil(cases.length / itemsPerPage)
                        ? Math.ceil(cases.length / itemsPerPage)
                        : currentPage + 1
                    )
                  }
                />
                <Pagination.Last
                  onClick={() =>
                    paginate(Math.ceil(cases.length / itemsPerPage))
                  }
                />
              </Pagination>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Forum;
