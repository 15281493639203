import React, { Component } from "react";

import Slideimage from "../SlideImage/Slideimage";
import "./Home.css";
import Footer from "../Footer/Footer";
import Feature from "../Features/Feature";
import Pricing from "../Pricing/Pricing";
import Feedback from "../Feedback/Feedback";
import Contact from "../Contact/Contact";
import Navbar from "../Navbar/Navbar";
import Test from "./test";
//import "./index.css";

export default function Home() {
  return (
    <>
      <Navbar />

      <div className="home6">
        <Slideimage />
      </div>
      <Feature />
      <Pricing />
      <Feedback />
      <Contact />

      <Footer />
    </>
  );
}
