// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home6{
    position: relative;
 
  }
  
  .top-services-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    z-index: 1;
    background-color: transparent;
    opacity: 0.8;
  }
  `, "",{"version":3,"sources":["webpack://./src/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;EAEpB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,cAAc;IACd,UAAU;IACV,UAAU;IACV,6BAA6B;IAC7B,YAAY;EACd","sourcesContent":[".home6{\n    position: relative;\n \n  }\n  \n  .top-services-container {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    margin: 0 auto;\n    width: 80%;\n    z-index: 1;\n    background-color: transparent;\n    opacity: 0.8;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
